import React from 'react'
import { Grid, useTheme } from '@mui/material'
import { FaqQuestionContainer } from './FaqQuestionContainer'
import { FaqHomeButton } from './FaqHomeButton'
import { FaqTitle } from '../../components/Faq/FaqTitle';

export function FaqVoting() {
    const theme = useTheme()
    return (
        <>
            <FaqTitle subTitle='Uncool Cats Voting'></FaqTitle>
            <Grid container spacing={6} sx={{
                paddingLeft: '289px',
                paddingRight: '289px',
                paddingBottom: '90px',
                background: '#7285A5' , // no clue why i had to add this
                [theme.breakpoints.down('md')]: {
                    // No design here, im yolo-ing
                    paddingLeft: '35px',
                    paddingRight: '35px',
                }
            }}>
                <Grid item xs={12}>
                    <FaqQuestionContainer title='Uncool Cats Voting? How does it work?'>
                        <div>
                            One Uncool Cat = One Vote for important decisions and project direction raised to the community to vote on.
                        </div>
                        <div>
                            “Vote” will be used to ensure the community collectively 
                            guides the future of the Uncool Cats projects and holders' voices are listened to in key 
                            roadmap decisions and development to guide the founders and devs towards a thriving project with community involvement. 
                        </div>
                    </FaqQuestionContainer>
                </Grid>
                <Grid item xs={12} textAlign='center' sx={{
                    marginTop: '60px'
                }}>
                    <FaqHomeButton/>
                </Grid>
            </Grid>
        </>
    )
}