import React from 'react'
import { Box, styled, Typography, useTheme } from '@mui/material'

const Wrapper = styled(Box)(({theme}) => ({
    border: `3px solid ${theme.palette.neutral.main}`,
    borderRadius: '20px',
    marginTop: '30px'
}))

const Fill = styled(Box)(({theme}) => ({
    height: '76px',
    borderRadius: '16px', // Use 16px because there's some weird padding issues at 20
    border: 'inherit',
    backgroundColor: theme.palette.secondary.main,
}))

export function MintUsageBar(props) {
    const theme = useTheme()
    const { remaining, total, hideBar, counterFontSize, counterFontSizeMd } = props
    // Not sure if we want the bar to be remaining, or taken
    // Monta - remaining
    const fillPct = ((remaining/total) * 100)+'%'

    return (
        <>
        <Typography variant="h1" sx={{
            fontSize: counterFontSizeMd !== "" ? counterFontSizeMd: '50px',
            fontWeight: 800,
            [theme.breakpoints.down('md')]: {
                fontSize: counterFontSize !== "" ? counterFontSize: '35px',
                fontWeight: 700,
            },
            [theme.breakpoints.down('lg')]: {
                fontSize: counterFontSize !== "" ? counterFontSize: '35px',
                fontWeight: 700,
            },
        }}>
            {remaining}/{total} Uncool Cats Left
        </Typography>
        {hideBar ? "" :
                <Wrapper>
                    <Fill sx={{width: fillPct}}/> 
                </Wrapper>
        }
        </>
    )
}