import React, {useEffect, useState} from 'react'

import { Typography, styled, Grid, useTheme, Button } from '@mui/material'
import { truncateAddress } from '../lib/util.js';
import { addTokenToMeta } from '../lib/interact.jsx';

export function SourPanel(props) {
    const theme = useTheme();

    const { onClaim, showClaim } = props;

    const [sourBalance, setSourBalance] = useState(-1);
    const [pendingSour, setPendingSour] = useState(-1);
    const [tokenIds, setTokenIds] = useState([]);
    const [wallet, setWallet] = useState("...");

    useEffect( async () => {
        setSourBalance(props.sourBalance)
        setPendingSour(props.pendingSour)
        setTokenIds(props.tokenIds)
        setWallet(props.wallet)
        // console.log("useEffect tokenIds: " + props.tokenIds)
        // console.log("useEffect pendingSour: " + props.pendingSour)
    }, [props.sourBalance,props.pendingSour, props.tokenIds, props.wallet])


    return (
        <Grid container spacing={0} sx={{
            textAlign: "center",
            maxWidth: "518px",
            maxHeight: "277px",
            background: `${theme.palette.neutral.main}`,
            borderRadius: "20px",
            border: "3px solid #000000",
            marginRight: "auto",
            marginLeft: "auto",
            [theme.breakpoints.down('md')]: {
                paddingTop: '43px',
                paddingBottom: '32px',
                maxWidth: '300px',
                maxHeight: '300px',
            },
        }}>
            <div style={{margin:"auto"}}>
                <Typography marginTop="18px" marginBottom="18px"variant="h3">Wallet Address: {truncateAddress(wallet)} </Typography>
                <Typography marginBottom="18px"variant="h3">Wallet Balance: {sourBalance >= 0 ? Number(Math.round(sourBalance+'e3')+'e-3') : '...' } $SOUR</Typography>
                <Typography marginBottom="18px"variant="h3">Pending: {pendingSour >= 0 ? Number(Math.round(pendingSour+'e3')+'e-3') : '...' } $SOUR</Typography>
                {showClaim ? 
                    <Button onClick={() => onClaim(tokenIds)} sx={{
                        marginTop: '25px',
                        border: `5px solid ${theme.palette.neutral.main}`,
                    }}>
                        Claim all
                    </Button> : 
                    <Button href="/wallet" sx={{
                        marginTop: '25px',
                        border: `5px solid ${theme.palette.neutral.main}`,
                    }}>
                        Go to claim
                    </Button>
                }
                <div>
                    {showClaim &&
                        <Button onClick={ () => addTokenToMeta()} sx={{
                            marginTop: '5px',
                            marginBottom: '28px',
                            border: `5px solid ${theme.palette.neutral.main}`,
                        }}>
                            Add $SOUR to MM 🦊
                        </Button>
                    }
                </div>
            </div>
        </Grid>
    )
}