import React from 'react'
import { Button, SvgIcon, styled } from '@mui/material'
import { UpArrow } from '../icons/up-arrow'

const ScrollButton = styled(Button)(({theme}) => ({
    borderRadius: '50%',
    maxWidth: '56px',
    minWidth: '56px',
    height: '56px',
    float: 'right',
    marginBottom: '32px',
    marginRight: '40px',
}))

export function ScrollToTopButton() {
    return (
        <ScrollButton onClick={() => window.scrollTo(0, 0)} sx={{
            display: { xs: 'none', md: 'flex' }
        }}>
            <SvgIcon viewBox='0 0 384.923 384.923'>
                <UpArrow/>
            </SvgIcon>
        </ScrollButton>
    )
}