import React from 'react'
import { Grid, Typography, useTheme, Box, styled } from '@mui/material'
import { DarkSection } from '../lib/DarkSection'
import UpperImage from '../../../static/img/diagonal-left.png';
import LowerImage from '../../../static/img/header-background.png';

const ImgContent = styled(`img`)(({theme}) => ({
    width: 'inherit',
    height: 'inherit',
    borderRadius: '20px',
    border: `8px solid ${theme.palette.primary.main}`,
    [theme.breakpoints.down('lg')]: {
        marginTop: 0,
        marginLeft: 0,
    }
}))

const ImgContainer = styled(`div`)(({theme}) => ({
    width: '794px',
    height: '525px',
    margin: 'auto',
    marginBottom: '82px',
    [theme.breakpoints.down('lg')]: {
        marginTop: 0,
        marginBottom: '48px',
        width: '320px',
        height: 'auto',
    }
}))

export function RatRace() {
    const theme = useTheme()
    return (
        <DarkSection
            upperImage={UpperImage}
            upperHeight='315px'
            lowerImage={LowerImage}
            lowerHeight='320px'
        >
            <Grid container alignItems='center' sx={{
                paddingLeft: '160px',
                paddingRight: '160px',
                justifyContent: 'center',
                [theme.breakpoints.down('lg')]: {
                    paddingLeft: '32px',
                    paddingRight: '32px',
                }
            }}>
                <Grid item xs={!2} md={7}>
                    <ImgContainer>
                        <ImgContent src='img/rat-race.png'/>
                    </ImgContainer>
                </Grid>
                <Grid item xs={12} md={7} align='center'>
                    <Typography variant="h1">THE RAT RACE</Typography>
                </Grid>
                <Grid item xs={12} md={7} marginTop='24px' align='center'>
                    <Typography component={'div'} variant="h2">
                        <div>Escape the rat race, become a teacher's pet. 
                            Are you still following?
                        </div>
                    </Typography>
                </Grid>
                <Grid item xs={12} md={7} marginTop='40px'>
                    <Typography component={'div'} variant="body1">
                        <br/>
                        <br/>
                        <div>
                            The Teacher's Pets are in an interactive racing game where you can race your pet against others! 
                            Play the rat run <a style={{textDecoration: "underline", color: "white"}} href="https://ratrun.netlify.app/">here</a>!
                        </div>
                        <br/>
                    </Typography>
                    <Box height="60px"/>
                </Grid>
            </Grid>
        </DarkSection>
    )
}