import React from 'react'
import { Paper, styled } from '@mui/material'
import { WallPaper } from './WallPaper'

const StyledPaper = styled(Paper)(({theme}) => ({
    background: `${theme.palette.neutral.main}`,
    boxShadow: 0
}))


export function DarkSection(props) {
    const {upperImage, upperHeight, lowerImage, lowerHeight, children} = props;

    return (
        <>
            {!!upperImage && !!upperHeight && 
                <WallPaper minHeight={upperHeight} image={upperImage}/>
            }
            <StyledPaper>
                {children}
            </StyledPaper>
            {!!lowerImage && !!lowerHeight && 
                <WallPaper minHeight={lowerHeight} image={lowerImage}/>
            }
        </>
    )
}