import React from 'react';
import { Paper, Box } from '@mui/material';
import { HomeGreeting } from '../../components/Homepage/HomeGreeting';
import { Introduction } from '../../components/Homepage/Introduction';
import { CatGallery } from '../../components/Homepage/CatGallery';
import { Pricing } from '../../components/Homepage/Pricing';
import { SourIntro } from '../../components/Homepage/SourIntro';
import { RatRace } from '../../components/Homepage/RatRace';
import { ScrollToTopButton } from '../../components/ScrollToTopButton'

function Homepage() {
    return (
            <Paper sx={{boxShadow: 0, zIndex: -2}}>
                <Box sx={{
                    margin: 'auto',
                    maxWidth: '2100px'
                }}>
                    <HomeGreeting/>
                    <Introduction/>
                    <CatGallery/>
                    <Pricing/>
                    <SourIntro/>
                    <RatRace/>
                    <ScrollToTopButton/>
                    <Box height='72px'/>
                </Box>
            </Paper>

    )
}

export default Homepage