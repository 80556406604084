import React, { useEffect, useState } from 'react'
import { Select, TextField, useTheme, styled, Paper, MenuItem } from '@mui/material'

const StyledMenuItem =  styled(MenuItem, {
    shouldForwardProp: (prop) => prop !== 'indexProp'
})(({theme, indexProp}) => ({
    backgroundColor: theme.palette.secondary.main,
    color: `${theme.palette.neutral.main}`,
    borderTop: indexProp === 0 ? 0 : `3px solid ${theme.palette.neutral.main}`,
    fontFamily: 'Special Elite',
    fontSize: '30px',
    width: '100%',
    display: 'block',
    textAlign: 'center',
}))

export function MintDropDown(props) {
    const shortList = [1, 2]
    const fullList = [1,2,3,4,5]

    // state variables
    const [isWhitelist, setIsWhitelist] = useState(false);
    const [options, setOptions] = useState(whitelist ? shortList: fullList)

    const { onChange, value, whitelist } = props

    const theme = useTheme()

    useEffect( () => {
        // console.log("mint phase updated, isWhitelist:  " + props.whitelist)
        setIsWhitelist(props.whitelist)
        setOptions(props.whitelist ? shortList: fullList);
    }, [props.whitelist])



    return (
        <Select 
            onChange={(event) => onChange(event.target.value)}
            value={value}
            MenuProps={{sx: {
                '& .MuiPaper-root': {
                    borderRadius: '20px',
                    border: `3px solid ${theme.palette.neutral.main}`,
                    '& .MuiList-root': {
                        padding: 0,
                    }
                }
            }}}
            sx={{
                backgroundColor: theme.palette.secondary.main,
                borderRadius: '20px',
                color: `${theme.palette.neutral.main}`,
                fontFamily: 'Special Elite',
                fontSize: '30px',
                minHeight: '74px',
                minWidth: '680px',
                alignContent: 'center',
                border: `3px solid ${theme.palette.neutral.main}`,
                [theme.breakpoints.down('md')]: {
                    minWidth: '312px',
                },
                '& .MuiSelect-icon': {
                    display: 'none'
                },
                '& .MuiOutlinedInput-notchedOutline': {
                    display: 'none',
                },
                '& .MuiOutlinedInput-input': {
                    padding: '8px !important',
                    border: 0,
                },
            }}
        >
            {options.map((option, index) => {
                if (index === 0) {
                    return <StyledMenuItem key={`dropdown_item_${index}`} indexProp={0} value={option}>{option}</StyledMenuItem>
                }
                return (
                    <StyledMenuItem key={`dropdown_item_${index}`} value={option}>{option}</StyledMenuItem>
                )
            })}
        </Select>
    )
}