import React from "react";
import { Button } from '@mui/material'
import { Link } from "react-router-dom";

export function FaqHomeButton() {
    return (
        <Link to='/faq' style={{
            textDecoration: 'none',
            color: 'inherit',
        }}>
            <Button onClick={() => window.scrollTo(0, 0)}>BACK TO FAQ</Button>
        </Link>
    )
}