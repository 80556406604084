import React  from 'react'
import { Grid, Typography, useTheme, Box, styled } from '@mui/material'
import { Link } from 'react-router-dom'

const ImgContent = styled(`img`)(({theme}) => ({
    width: 'inherit',
    height: 'inherit',
    borderRadius: '20px',
    border: `1px solid ${theme.palette.primary.main}`, //TODO: Get borderless image
    [theme.breakpoints.down('lg')]: {
        marginTop: 0,
        marginLeft: 0,
    }
}))

const ImgContainer = styled(`div`)(({theme}) => ({
    width: '419px',
    height: '405px',
    margin: 'auto',
    marginBottom: '82px',
    [theme.breakpoints.down('lg')]: {
        marginTop: 0,
        marginBottom: '48px',
        width: '216px',
        height: '209px',
    }
}))

export function SourIntro() {
    const theme = useTheme();
    return (
        <Grid container alignItems='center' sx={{
            paddingLeft: '160px',
            paddingRight: '160px',
            justifyContent: 'center',
            [theme.breakpoints.down('lg')]: {
                paddingLeft: '32px',
                paddingRight: '32px',
            }
        }}>
            <Grid item xs={12} md={7}>
                <ImgContainer>
                    <ImgContent src='img/sour-milk.png'/>
                </ImgContainer>
            </Grid>
            <Grid item xs={12} md={7} align='center'>
                <Typography variant="h1">
                    $SOUR TOKENS
                </Typography>
            </Grid>
            <Grid item xs={12} md={7} marginTop='24px' align='center'>
                <Typography component={'div'} variant="h2">
                    <div>
                        This Milk got left out too long. 
                        Make sure the Uncool Cats don't drink this - 
                        these nerds can't afford to miss class!
                    </div>
                </Typography>
            </Grid>
            <Grid item xs={12} md={7} marginTop='40px'>
                <Typography component={'div'} variant="body1">
                    <div>
                        Hold your Uncool Cat in your desired wallet to passively yield $SOUR - which is the official Uncool Cats ERC-20 token! Each Uncool Cat NFT held earns 10 $SOUR per day! 
                    </div>
                    <br/>
                    <br/> 
                    There is no staking involved! Just HODL Uncool Cats and <Link to='/sour' style={{ textDecoration: 'none' }}> <span style={{color: `${theme.palette.secondary.main}`, fontWeight: 'normal'}}>CLAIM $SOUR </span> </Link> tokens.
                    <br/>
                    <br/>
                </Typography>
                <Box height="60px"/>
            </Grid>
        </Grid>
    )
}