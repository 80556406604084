import React from 'react'
import { Grid, useTheme, Link } from '@mui/material'
import { FaqQuestionContainer } from './FaqQuestionContainer'
import { FaqHomeButton } from './FaqHomeButton'
import { FaqTitle } from '../../components/Faq/FaqTitle';

export function FaqRatRace() {
    const theme = useTheme()
    return (
        <>
            <FaqTitle subTitle='The Rat Race'></FaqTitle>
            <Grid container spacing={6} sx={{
                paddingLeft: '289px',
                paddingRight: '289px',
                paddingBottom: '90px',
                background: '#7285A5' ,
                [theme.breakpoints.down('md')]: {
                    // No design here, im yolo-ing
                    paddingLeft: '35px',
                    paddingRight: '35px',
                }
            }}>

                <Grid item xs={12} textAlign='center' sx={{
                    marginTop: '60px'
                }}>
                    <FaqHomeButton/>
                </Grid>
            </Grid>
        </>
    )
}