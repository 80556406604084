import React, {useState, useEffect} from 'react'
import { Grid, Typography, styled, useTheme, Box, Button } from '@mui/material'
import { connectWallet, fetchBaseURI, getCurrentWalletConnected, fetchTokenIds, fetchAccruedSour, fetchSourBalance, claimSour } from "../lib/interact.jsx";
import { WalletCard } from './WalletCard.jsx';
import { Warning } from '../lib/Warning';
import { truncateAddress } from '../lib/util.js';

import {SourPanel} from '../Sour/SourPanel';


const StyledPanels = styled(`div`)(({theme}) => ({
width:"525px",
margin:"auto",
display:"flex", 
flexDirection:'column',
    [theme.breakpoints.down('md')]: {
        width:"300px",
        flexDirection: 'column',
    },

}))


export function WalletIntro() {
    const [walletAddress, setWallet] = useState("");
    const [tokenIds, setTokenIds] = useState([]);
    const [status, setStatus] = useState("...");
    const [txUrl, setTxUrl] = useState("");
    const [cardData, setCardData] = useState([]);

    const [loadingStatus, setLoadingStatus] = useState("Loading... 😺");

    const [sourBalance, setSourBalance] = useState("...");
    const [sourSupply, setSourSupply] = useState("...");
    const [accruedSour, setAccruedSour] = useState("...");

    const sampleData = [
        {
            "id": "1",
            "imageLink": "https://uncoolcats.mypinata.cloud/ipfs/QmWXBbiiGqy8a8GvRJEhdFL1QYeb1ErD35aT3PBcjv5gJq/1.png"
        },
        {
            "id": "2",
            "imageLink": "https://uncoolcats.mypinata.cloud/ipfs/QmWXBbiiGqy8a8GvRJEhdFL1QYeb1ErD35aT3PBcjv5gJq/2.png"
        },
        {
            "id": "3",
            "imageLink": "https://uncoolcats.mypinata.cloud/ipfs/QmWXBbiiGqy8a8GvRJEhdFL1QYeb1ErD35aT3PBcjv5gJq/3.png"
        },
        {
            "id": "26",
            "imageLink": "https://uncoolcats.mypinata.cloud/ipfs/QmWXBbiiGqy8a8GvRJEhdFL1QYeb1ErD35aT3PBcjv5gJq/26.png"
        },
        {
            "id": "18",
            "imageLink": "https://uncoolcats.mypinata.cloud/ipfs/QmWXBbiiGqy8a8GvRJEhdFL1QYeb1ErD35aT3PBcjv5gJq/18.png"
        },
        {
            "id": "25",
            "imageLink": "https://uncoolcats.mypinata.cloud/ipfs/QmWXBbiiGqy8a8GvRJEhdFL1QYeb1ErD35aT3PBcjv5gJq/25.png"
        },
        {
            "id": "21",
            "imageLink": "https://uncoolcats.mypinata.cloud/ipfs/QmWXBbiiGqy8a8GvRJEhdFL1QYeb1ErD35aT3PBcjv5gJq/21.png"
        },
        {
            "id": "8",
            "imageLink": "https://uncoolcats.mypinata.cloud/ipfs/QmWXBbiiGqy8a8GvRJEhdFL1QYeb1ErD35aT3PBcjv5gJq/8.png"
        },
        {
            "id": "9",
            "imageLink": "https://uncoolcats.mypinata.cloud/ipfs/QmWXBbiiGqy8a8GvRJEhdFL1QYeb1ErD35aT3PBcjv5gJq/9.png"
        },
        {
            "id": "27",
            "imageLink": "https://uncoolcats.mypinata.cloud/ipfs/QmWXBbiiGqy8a8GvRJEhdFL1QYeb1ErD35aT3PBcjv5gJq/27.png"
        },
        {
            "id": "11",
            "imageLink": "https://uncoolcats.mypinata.cloud/ipfs/QmWXBbiiGqy8a8GvRJEhdFL1QYeb1ErD35aT3PBcjv5gJq/11.png"
        },
        {
            "id": "28",
            "imageLink": "https://uncoolcats.mypinata.cloud/ipfs/QmWXBbiiGqy8a8GvRJEhdFL1QYeb1ErD35aT3PBcjv5gJq/28.png"
        },
        {
            "id": "29",
            "imageLink": "https://uncoolcats.mypinata.cloud/ipfs/QmWXBbiiGqy8a8GvRJEhdFL1QYeb1ErD35aT3PBcjv5gJq/29.png"
        },
        {
            "id": "14",
            "imageLink": "https://uncoolcats.mypinata.cloud/ipfs/QmWXBbiiGqy8a8GvRJEhdFL1QYeb1ErD35aT3PBcjv5gJq/14.png"
        },
        {
            "id": "19",
            "imageLink": "https://uncoolcats.mypinata.cloud/ipfs/QmWXBbiiGqy8a8GvRJEhdFL1QYeb1ErD35aT3PBcjv5gJq/19.png"
        },
        {
            "id": "22",
            "imageLink": "https://uncoolcats.mypinata.cloud/ipfs/QmWXBbiiGqy8a8GvRJEhdFL1QYeb1ErD35aT3PBcjv5gJq/22.png"
        },
        {
            "id": "17",
            "imageLink": "https://uncoolcats.mypinata.cloud/ipfs/QmWXBbiiGqy8a8GvRJEhdFL1QYeb1ErD35aT3PBcjv5gJq/17.png"
        }
    ];

    const theme = useTheme();

    function addWalletListener() {
        // console.log("adding listener")
        if (window.ethereum.chainId) {
            // console.log("eth detected")
            if (window.ethereum.chainId !== "0x1") {
                // console.log("Rinkeby network not found, found chain " + window.ethereum.chainId)
            } else {
                // console.log("adding listener")
                window.ethereum.on("accountsChanged", (accounts) => {
                    // console.log("Account changed")
                    if (accounts.length > 0) {
                        setWallet(accounts[0]);
                        setStatus("Wallet Connected: " + truncateAddress(accounts[0]));
                        // console.log("accountschanged triggered")
                        fetchTokenIds().then(accountTokens => {
                            // console.log("tokenIds" + accountTokens);
                            let data = [];
                            for (let i = 0; i < accountTokens.length; i++ ) {
                                data[i] = {
                                    id: accountTokens[i],
                                    imageLink: "https://uncoolcats.mypinata.cloud/ipfs/QmWXBbiiGqy8a8GvRJEhdFL1QYeb1ErD35aT3PBcjv5gJq/" + accountTokens[i] + ".png",
                                }
                            }
                            if (accountTokens.length === 0) {
                                setLoadingStatus("No cats found. 😿");
                            }
                            // console.log(data);
                            setCardData(data);
                            setTokenIds(accountTokens);
                            fetchAccruedSour(accountTokens).then(pendingSour => {
                                // console.log("pendingSour"  + pendingSour);
                                setAccruedSour(pendingSour);
                                fetchSourBalance().then( userSourBalance => {
                                    // console.log("sourBalance" +userSourBalance);
                                    setSourBalance(userSourBalance)
                                });
                            })
                        })

                    } else {
                        setWallet("");
                        setStatus("No accounts connected");
                    }
                });
            }
        } else {
                // console.log("couldn't add listener")
            setStatus("Please connect to Metamask");
        }
    }

    const onClaimPressed  = async (ids) => {
        // console.log("claimP:" + tokenIds)
        // console.log(ids)
        setStatus("Claiming...")
        const txInfo = await claimSour(ids)
        if (txInfo.success) {
            setTxUrl(txInfo.txUrl)
        } else {
            setStatus(txInfo.status);
        }
    }

    useEffect( async () => {
        document.onreadystatechange = async () => {
            let status = await connectWallet();
            // console.log(status);

            getCurrentWalletConnected().then( (resp) => {
                setStatus(resp.status);
                if (resp.address !== "") {
                    setStatus(resp.status + " : " + truncateAddress(status.address));
                    setWallet(resp.address);
                } 
            });

            if (status.address !== "" ) {
                setWallet(status.address);
                setStatus(status.status + " : " + truncateAddress(status.address));
                let accountTokens = await fetchTokenIds();
                // console.log("tokenIds" + accountTokens);
                setTokenIds(accountTokens);
                let data = [];
                for (let i = 0; i < accountTokens.length; i++ ) {
                    data[i] = {
                        id: accountTokens[i],
                        imageLink: "https://uncoolcats.mypinata.cloud/ipfs/QmWXBbiiGqy8a8GvRJEhdFL1QYeb1ErD35aT3PBcjv5gJq/" + accountTokens[i] + ".png",
                    }
                }
                if (accountTokens.length === 0) {
                    setLoadingStatus("No cats found. 😿");
                }
                // console.log(data);
                setCardData(data);

                let pendingSour = await fetchAccruedSour(accountTokens);
                setAccruedSour(pendingSour)
                // console.log("pendingSour"  + pendingSour);
                let userSourBalance = await fetchSourBalance();
                setSourBalance(userSourBalance)
                // console.log("sourBalance" +userSourBalance);
            }

        }

        addWalletListener();

    }, [walletAddress]);

    return (
        <Grid container textAlign='center' sx={{
            margin:'auto',
            paddingRight: '51px',
            paddingLeft: '51px',
            paddingBottom: '400px',
            [theme.breakpoints.down('lg')]: {
                paddingLeft: '16px',
                paddingRight: '16px',
                justifyContent: 'center',
            }
        }}>
                <Grid item xs={12}>
                    <Typography variant="h1">Wallet</Typography>
                </Grid>
                <Grid item xs={12} alignItems="center" marginTop='32px' textAlign='center' >
                    { status !== "" && <Warning url={txUrl} label={status} /> }
                </Grid>
                <Grid item xs={12} alignItems="center" marginTop='32px' textAlign='center' >
                    <StyledPanels>
                        <SourPanel onClaim={onClaimPressed} showClaim={true} wallet={walletAddress} tokenIds={tokenIds} sourBalance={sourBalance} pendingSour={accruedSour}/>
                    </StyledPanels>
                </Grid>
                {

                    cardData.length === 0 ?
                    <Grid item xs={12} marginTop="30px">
                        <Typography variant="h3">{loadingStatus}</Typography>
                    </Grid> : cardData.map((item) => {
                        return (
                            <Grid key={`wcard_${item.id}`} item xs={12} md={6} lg={4} xl={3} marginTop='64px' textAlign='center'>
                                <WalletCard id={item.id} imageLink={item.imageLink} onClaimPressed={onClaimPressed}/>
                            </Grid>
                        )
                    })
                }
                <Box height="300px"/>

        </Grid>
    )
}