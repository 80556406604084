import React from "react";
import { Switch, BrowserRouter as Router, Route, Redirect } from "react-router-dom";

import Homepage from "./Homepage/Homepage";
import theme from "../theme";
import { ThemeProvider } from '@mui/material'
import { Header } from '../components/Header'
import { Footer } from '../components/Footer'

import Faq from './Faq/Faq';
import Sour from './Sour/Sour';
import Wallet from './Wallet/Wallet';
import Mint from './Mint/Mint';

import { FaqGeneral } from '../components/Faq/FaqGeneral';
import { FaqSour } from '../components/Faq/FaqSour';
import { FaqLicense } from '../components/Faq/FaqLicense';
import { FaqRatRace } from '../components/Faq/FaqRatRace';
import { Terms } from '../components/Terms/Terms';
import ScrollToTop  from '../ScrollToTop';


function App() {
  return (
    <ThemeProvider theme={theme}>
        <Router>
            <Header/>
            <ScrollToTop />
            <Switch>
                <Route exact path='/'>
                  <Homepage/>
                </Route>
                <Route path="/faq">
                  <Faq/>
                </Route>
                <Route path="/faq-general-info">
                    <FaqGeneral/>
                </Route>
                
                <Route path="/faq-sour">
                    <FaqSour/>
                </Route>
                <Route path="/faq-license">
                    <FaqLicense/>
                </Route>
                <Route path="/faq-rat-race">
                    <FaqRatRace/>
                </Route>

                <Route path="/terms">
                  <Terms/>
                </Route>

                <Route path="/sour">
                  <Sour/>
                </Route>

                <Route path="/wallet">
                  <Wallet/>
                </Route>

                <Route path="/mint">
                  <Mint/>
                </Route>

            </Switch>
            <Footer/>
        </Router>
    </ThemeProvider>
  );
}

export default App