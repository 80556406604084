import React from 'react';
import { Paper, Box } from '@mui/material';
import { Switch, Route, useLocation } from "react-router-dom";
import { FaqHome } from '../../components/Faq/FaqHome';
import { FaqGeneral } from '../../components/Faq/FaqGeneral';
import { FaqSour } from '../../components/Faq/FaqSour';
import { FaqLicense } from '../../components/Faq/FaqLicense';
import { FaqRatRace } from '../../components/Faq/FaqRatRace';
import { FaqVoting } from '../../components/Faq/FaqVoting';
import ScrollToTop  from '../../ScrollToTop';

export default function Faq() {
    return (
        <Paper sx={{boxShadow: 0}}>
            <Box sx={{
                margin: 'auto',
                maxWidth: '2100px'
            }}>
                <ScrollToTop />
                <Switch>
                    <Route exact path="/faq/">
                        <FaqHome/>
                    </Route>
                    {/* would be nice to figure out how to do clientside routing properly in the future. */}
                    <Route path="/faq-general-info/">
                        <FaqGeneral/>
                    </Route>
                    
                    <Route path="/faq-sour/">
                        <FaqSour/>
                    </Route>
                    <Route path="/faq-license/">
                        <FaqLicense/>
                    </Route>
                    <Route path="/faq-rat-race/">
                        <FaqRatRace/>
                    </Route>
                    <Route path="/faq-vote/">
                        <FaqVoting/>
                    </Route>
                </Switch>
            </Box>
        </Paper>
    )
}