import React, { useEffect, useState }  from 'react';
import { Tabs, Tab, styled, Button } from '@mui/material';
import { useLocation } from 'react-router-dom'
import { connectWallet, fetchTotalSupply, getCurrentWalletConnected, mintNFT, getPublicMintActive, getWhitelistMintActive } from "./lib/interact.jsx";


export function HeaderTabs() {
    const location = useLocation()

    //State variables
    const [wallet, setWallet] = useState("");
    const [status, setStatus] = useState("");

    // Temporary hack to get the indicator on the tabs working
    const resolvePath = (pathname) => {
        if (pathname === '/mint') return false
        return `/${pathname.split('/')[1]}`
    }

    const HeaderTabs = styled(Tabs)(({theme}) => ({
        textAlign: 'right',
        '& > *': {
            marginRight: '8px',
            marginLeft: '120px',
        },
        [theme.breakpoints.down('md')]: {
            '& > *': {
                marginRight: '0',
            }
        }
    }))

    function addWalletListener() {
        // console.log("adding listener")
        if (window.ethereum.chainId) {
            // console.log("eth detected")
            if (window.ethereum.chainId !== "0x1") {
                // console.log("Mainnet network not found, found chain " + window.ethereum.chainId)
                // console.log("ethereum: " + JSON.stringify(window.ethereum))
            } else {
                // console.log("adding listener")
                window.ethereum.on("accountsChanged", (accounts) => {
                    // console.log("Account changed")
                    if (accounts.length > 0) {
                        setWallet(accounts[0]);
                        setStatus("Wallet Connected:");
                    } else {
                        setWallet("");
                        setStatus("No accounts connected");
                    }
                });
            }
        } else {
                // console.log("couldn't add listener")
            setStatus("Please connect to Metamask");
        }
    }


    useEffect( async () => {
        getCurrentWalletConnected().then( (resp) => {
            setStatus(resp.status);
            if (resp.address !== "") {
                setWallet(resp.address);
            } 
        });

        addWalletListener();
    }, [wallet])

    const connectPressed = async (event) => {
	  const walletResponse = await connectWallet();
	  setStatus(walletResponse.status);
    //   console.log(walletResponse.address);
      if (walletResponse.address !== "") {
          setWallet(walletResponse.address)
          addWalletListener()
      } 
      // setConnected = true if true
      // display error if didn't work
    }


    const connectButton = <Button onClick={() => {connectPressed()}} sx={{ maxWidth: '241px', maxHeight: '55px', marginRight: '16px', }}> CONNECT</Button>;
    const connectedButton = <Button  sx={{ maxWidth: '241px', maxHeight: '55px', marginRight: '16px', }}> CONNECTED </Button>;

    return (
        <>
            <HeaderTabs value={resolvePath(location.pathname)}>
                <Tab key="home" label="Home" href='/' value='/home'/>
                <Tab key="faq" label="FAQ" href='/faq' value='/faq'/>
                <Tab key="wallet" label="Wallet" href='/wallet' value='/wallet'/>
                <Tab key="sour" label="Sour" href='/sour' value='/sour'/>
                <Tab key="mint" value='/mint' sx={{marginRight: '24px'}} label={wallet == "" ? connectButton : connectedButton}/>
            </HeaderTabs>
        </>
    )
}