import React, { useEffect, useState } from 'react'
import { Grid, Typography, styled, useTheme, Box, Button } from '@mui/material'
import { Link } from 'react-router-dom'
import { MintUsageBar } from './MintUsageBar';
import { MintDropDown } from './MintDropDown';
import { MintDataList } from './MintDataList';
import { Warning } from '../lib/Warning';
import { truncateAddress } from '../lib/util.js';

import { connectWallet, fetchTotalSupply, getCurrentWalletConnected, mintNFT, getPublicMintActive, getWhitelistMintActive } from "../lib/interact.jsx";

const Image = styled(`img`)(({theme}) => ({
    width: '451px',
    display: 'block',
    margin: 'auto',
    [theme.breakpoints.down('md')]: {
        width: '200px',
        height: 'auto',
    },
}))

export function MintIntro() {
    //State variables
    const [walletAddress, setWallet] = useState("");
    const [totalSupply, setTotalSupply] = useState(0);
    const [status, setStatus] = useState("Please connect wallet")
    const [hideMint, setHideMint] = useState(true)
    const [isWhiteList, setIsWhitelist] = useState(true)
    const [txUrl, setTxUrl] = useState("")

    function addWalletListener() {
        // console.log("adding listener")
        if (window.ethereum.chainId) {
            // console.log("eth detected")
            if (window.ethereum.chainId !== "0x1") { //
                // console.log("Mainnet network not found, found chain " + window.ethereum.chainId)
                setStatus("Please connect to Mainnet");
            } else {
                // console.log("adding listener")
                window.ethereum.on("accountsChanged", (accounts) => {
                    // console.log("Account changed")
                    if (accounts.length > 0) {
                        setWallet(accounts[0]);
                        setStatus("Wallet Connected:");
                    } else {
                        setWallet("");
                        setStatus("No accounts connected");
                    }
                });
            }
        } else {
                // console.log("couldn't add listener")
            setStatus("Please connect to Metamask");
        }
    }

    useEffect( async () => {
        const publicMintActive = await getPublicMintActive();
        const whitelistMintActive = await getWhitelistMintActive();

        // console.log("Public mint active: " + publicMintActive);
        // console.log("Whitelist mint active: " + whitelistMintActive);

        // setHideMint(!publicMintActive && !whitelistMintActive);
        // setIsWhitelist(whitelistMintActive && !publicMintActive); // TODO: update for whitelist/public

        getCurrentWalletConnected().then( (resp) => {
            setWallet(resp.address);
            if (resp.address !== "") {
                setConnected(true)
                setStatus(resp.status);
            } else {
                setConnected(false)
            }
        });

        let supplyMinted = await fetchTotalSupply()
        // console.log(supplyMinted);
        setTotalSupply(supplyMinted);

        addWalletListener();
    }, [walletAddress])

    const theme = useTheme();

    const [dropDownValue, setDropDownValue] = React.useState(1)

    const [connected, setConnected] = React.useState(false)



    const connectPressed = async (event) => {
	  const walletResponse = await connectWallet();
	  setWallet(walletResponse.address);
	  setStatus(walletResponse.status);
    //   console.log(walletAddress);
      if (walletResponse.address !== "") {
          setWallet(walletResponse.address)
          setConnected(true)
          let supplyMinted = await fetchTotalSupply()
        //   console.log(supplyMinted);
          setTotalSupply(supplyMinted);
          addWalletListener()
      } 
      // setConnected = true if true
      // display error if didn't work
    }

    const mintPressed = async (event) => {
        const txInfo = await mintNFT(dropDownValue, isWhiteList )
        setStatus(txInfo.status)
        if (txInfo.success) {
            setTxUrl(txInfo.txUrl)
        }
    }


    return (
        ( hideMint ? <Grid container direction='row-reverse' sx={{
            paddingRight: '344px',
            paddingLeft: '344px',
            textAlign: 'center',
            justifyContent: 'center',
            paddingBottom: '400px',
            [theme.breakpoints.down('lg')]: {
                paddingLeft: '32px',
                paddingRight: '32px',
            }
        }}>
            <Grid item xs={12}>
                <Typography variant="h1">Become a lucky owner of an Uncool Cat</Typography>
            </Grid>
            <Grid item xs={12} marginTop='24px'>
                <Typography variant="h2">
                    Or even a crazy NFT cat lady, who knows
                </Typography>
            </Grid>
            <Grid item xs={12} marginTop='24px'>
                <Image src='img/evilcat.png' />
            </Grid>
            
        </Grid>
        : 
        <Grid container direction='row-reverse' sx={{
            paddingRight: '344px',
            paddingLeft: '344px',
            textAlign: 'center',
            justifyContent: 'center',
            [theme.breakpoints.down('lg')]: {
                paddingLeft: '32px',
                paddingRight: '32px',
            }
        }}>
            <Grid item xs={12}>
                <Typography variant="h1">Become a lucky owner of an Uncool Cat</Typography>
            </Grid>
            <Grid item xs={12} marginTop='24px'>
                <Typography variant="h2">
                    Or even a crazy NFT cat lady, who knows
                </Typography>
            </Grid>
            <Grid item xs={12} marginTop='24px'>
                <Image src='img/evilcat.png' />
                <Warning url={txUrl} label={walletAddress !== "" ? status + " : " + truncateAddress(walletAddress) : status }/>
            </Grid>
            <Grid item xs={12} marginTop='36px'>
                <Typography variant="body2" sx={{
                    fontSize: '32px',
                    [theme.breakpoints.down('md')]: {
                        fontSize: '25px',
                    },
                }}>
                    {isWhiteList ? "Mint is currently only live for whitelisted addresses!" : "How many cats do you want to adopt?" }
                </Typography>
            </Grid>
            <Grid item xs={12} marginTop='24px'>
                <Typography variant="h2" marginTop="24px" marginBottom="8px">{isWhiteList ? "up to 2 cats per WALLET - WHITELIST ONLY" : " up to 5 cats per transaction"}</Typography>
                <MintDropDown
                    value={dropDownValue}
                    whitelist={isWhiteList}
                    onChange={
                        (value) => setDropDownValue(value)
                    }
                />
            </Grid>
            <Grid item xs={12} marginTop='40px'>
                {
                    connected ? 
                    <Button sx={{ fontSize:"35px", minWidth:"300px", minHeight:"80px"}} onClick={() => mintPressed()}>
                        {isWhiteList ? "Whitelist Mint" :  "Mint"}
                    </Button> : 
                    <Button  sx={{ fontSize:"35px", minWidth:"300px", minHeight:"80px"}} onClick={() => connectPressed()}>
                        CONNECT
                    </Button>
                }
                
            </Grid>
            <Grid item xs={12} marginTop='96px'>
                <MintUsageBar remaining={-1*(totalSupply-6969)} total={6969}/>
            </Grid>
            <Grid item xs={12} marginTop='24px'>
                <MintDataList label="Cost of mint per cat" data={"0.0420 ETH"}/>
            </Grid>
            <Grid item xs={12} marginTop='64px'>
                <Typography variant="h2">
                    Questions left?
                </Typography>
            </Grid>
            <Grid item xs={12} marginTop='28px'>
                <Link to='/faq'>
                    <Button>
                        FAQ
                    </Button>
                </Link>
            </Grid>
            <Grid item xs={12} marginTop='40px'>
                <Box height='40px'/>
            </Grid>
        </Grid>)
    )
}
