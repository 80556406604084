import React, {useState, useEffect} from 'react'
import { Box, styled, Grid, Button, useTheme } from '@mui/material'
import OpenSeaLogoWhite  from '../../icons/cube.png';

import { fetchAccruedSourSingle } from "../lib/interact.jsx";

const OpenSeaBaseURL ="https://opensea.io/assets/0x5f9e300108fb156cfbe21c48a870876e97745af9/"

const Container = styled(Box)(({theme}) => ({
    width: '320px',
    height: '421px',
    paddingTop: '30px',
    borderRadius: '20px',
    margin: 'auto',
    background: theme.palette.neutral.main,
    border: '3px solid #000000',
    [theme.breakpoints.down('lg')]: {
        paddingTop: '30px',
        width: '336px',
        height: '415px',
    }
}))



const Label = styled(Box)(({theme}) => ({
    width: '242px',
    height: '42px',
    borderRadius: '20px',
    background: '#74B08F',
    border: '3px solid #FFFFFF',
    fontFamily: 'Special Elite',
    fontSize: '18px',                                                                                                                                                     
    textAlign:'center',
    verticalAlign: 'middle',
    display: "table-cell",
    margin: 'auto',
    [theme.breakpoints.down('lg')]: {
        fontSize: '18px',
        width: '340px',
        height: '50px',
    }
}))

const OpenSeasButton = styled(Button)(({theme}) => ({
    margin: 'auto',
    marginLeft: '30px',
    width: '48px',
    height: '48px',
    border: "3px solid #FFFFFF",
    [theme.breakpoints.down('lg')]: {
        minWidth: '42px !important',
        maxWidth: '42px',
        height: '42px',
        marginTop: '4px',
        marginLeft: '15px',
    },
}))


const ClaimLabel = styled(Box)(({theme}) => ({
    paddingTop: '4px',
    width: '200px',
    height: '35px',
    borderRadius: '20px',
    background: '#74B08F',
    border: '3px solid #FFFFFF',
    fontFamily: 'Special Elite',
    fontSize: '15px',                                                                                                                                                     
    textAlign:'center',
    margin: 'auto',
    verticalAlign: 'middle',
    display: "table-cell",
    [theme.breakpoints.down('lg')]: {
        fontSize: '13px',
        width: '205px',
        height: '30px',
    }
}))

const ClaimButton = styled(Button)(({theme}) => ({
    // margin: 'auto',
    border: '3px solid #FFFFFF',
    borderRadius: '20px',
    fontSize: '12px',                                                                                                                                                     
    textAlign:'center',
    margin: 'auto',
    verticalAlign: 'middle',
    display: "table-cell",
    marginLeft: "22px", 
    [theme.breakpoints.down('lg')]: { 
        marginLeft: "15px", 
    } ,
    /*
    width: '72px',
    height: '72px',
    border: "3px solid #FFFFFF",
    borderRadius: "50%",
    [theme.breakpoints.down('lg')]: {
        minWidth: '42px !important',
        maxWidth: '42px',
        height: '42px',
    },
    */
}))



const Image = styled(`img`)(({theme}) => ({
    margin: 'auto',
    width: '203px',
    height: '203px',
    border: "3px solid #FFFFFF",
    borderRadius: "20px",
    [theme.breakpoints.down('lg')]: {
        width: '189px',
        height: '189px',
    },
}))



const IconImage = styled(`img`)(({theme}) => ({
    width: '32px',
    height: '32px',
    filter: 'invert(100%)',
    [theme.breakpoints.down('lg')]: {
        width: '27px',
        height: '27px',
    },
}))

export function WalletCard(props) {
    const [sourPending, setSourPending] = useState(0);

    const { id, imageLink, onClaimPressed } = props
    const theme = useTheme()

 
    useEffect( async () => {
        let sour = await fetchAccruedSourSingle(id)
        // console.log("id: " + id + " sour: " + sour)
        setSourPending(sour);
    })

    return (
        <Container>
            <Grid container textAlign='center' sx={{
                paddingX: '20px',
                [theme.breakpoints.down('lg')]: {
                    paddingX: '24px',
                }
            }}>
                <Grid item xs={8} lg textAlign='center'>
                    <Label>
                        #{id}
                    </Label>
                </Grid>
                <Grid item xs={4} lg='auto'>
                    <a href={'filler'}>
                        <OpenSeasButton href={OpenSeaBaseURL+id}>
                            <IconImage src={OpenSeaLogoWhite}/>
                        </OpenSeasButton>
                    </a>
                </Grid>
                <Grid item xs={12} marginTop='30px'>
                    <Image src={imageLink}/>
                </Grid>
                <Grid flex item xs={8} marginTop='30px'>
                    <ClaimLabel>
                        🥛$SOUR: <span style={{color: "yellow"}}>{sourPending.toFixed(2)}</span>
                    </ClaimLabel>
                </Grid>
                <Grid item xs={4} marginTop='30px'>
                    <ClaimButton onClick={()=> { onClaimPressed([id])}}>CLAIM</ClaimButton>
                </Grid>
            </Grid>

        </Container>
    )
}