import React from 'react'
import { Grid, useTheme, Link } from '@mui/material'
import { FaqQuestionContainer } from './FaqQuestionContainer'
import { FaqHomeButton } from './FaqHomeButton'
import { FaqTitle } from '../../components/Faq/FaqTitle';

export function FaqSour() {
    const theme = useTheme()
    return (
        <>
            <FaqTitle subTitle='$SOUR'></FaqTitle>
            <Grid container spacing={6} sx={{
                paddingLeft: '289px',
                paddingRight: '289px',
                paddingBottom: '90px',
                background: '#7285A5' , // no clue why i had to add this
                [theme.breakpoints.down('md')]: {
                    // No design here, im yolo-ing
                    paddingLeft: '35px',
                    paddingRight: '35px',
                }
            }}>
                <Grid item xs={12}>
                    <FaqQuestionContainer title='What exactly is $SOUR?'>
                        <div>
                            Milk that’s gone bad, duh!
                        </div>
                        <div>
                            1 $SOUR = 1 $SOUR - to be clear, this is NOT an investment and has NO economic value
                        </div>
                        <br/>
                        <div>
                            Given potential regulations that may have an impact on how an NFT is viewed by the 
                            world and also by governing agencies [SEC rules, particularly towards the Howey Test] 
                            we want to ensure longevity and stability for our holders through our staking token. 
                            <br/><br/>
                            This project will not be distributing 'royalties' to token holders just to be clear or promising anything of promised future value. 
                            We plan to incentivize the community and holding of our NFTs through more meaningful ways that are not potentially disruptive to buyers. 
                            <br/><br/>
                        </div>
                    </FaqQuestionContainer>
                </Grid>
                <Grid item xs={12}>
                    <FaqQuestionContainer title='What is the $SOUR distribution timeline?'>
                        <div>
                            $SOUR is distributed daily for 3 years at a rate of 10 $SOUR per day per Uncool Cat
                        </div>

                    </FaqQuestionContainer>
                </Grid>
                <Grid item xs={12}>
                    <FaqQuestionContainer title='How do I yield $SOUR?'>
                        <div>
                            Everyone who owns an Uncool Cat will automatically yield $SOUR tokens. You can see the current supply and smart contract <Link>here</Link>.
                        </div>
                        <br/>
                        <div>
                            This page will also allow you to claim your pending $SOUR by paying a small amount of gas.
                        </div>
                    </FaqQuestionContainer>
                </Grid>
                <Grid item xs={12} textAlign='center' sx={{
                    marginTop: '60px'
                }}>
                    <FaqHomeButton/>
                </Grid>
            </Grid>
        </>
    )
}