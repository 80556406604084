import React from 'react'
import { Button, SvgIcon, styled } from '@mui/material';

import DiscordLogoWhite  from '../icons/discord.png';
import OpenSeaLogoWhite  from '../icons/cube.png';
import TwitterLogoWhite  from '../icons/twitter.png';

const LinkButton = styled(Button)(({theme}) => ({
    borderRadius: '50%',
    maxWidth: '56px',
    minWidth: '56px',
    height: '56px',
    [theme.breakpoints.down('md')]: {
        maxWidth: '52px',
        minWidth: '52px',
        height: '52px',
    }
}))

const LinkIcon = styled(`img`)(({theme}) => ({
    width:'32px',
    height: '32px',
    filter: 'invert(100%)'
}))

export function DiscordButton(props) {
    const { sx } = props
    return (
        <a href="https://www.discord.gg/uncoolcats">
            <LinkButton sx={sx}>
                    <LinkIcon src={DiscordLogoWhite}/>
            </LinkButton>
        </a>
    )
}
export function OpenSeasButton(props) {
    const { sx } = props
    return (
    <a href="https://opensea.io/collection/uncool-cats-nft">
            <LinkButton sx={sx}>
                    <LinkIcon src={OpenSeaLogoWhite}/>
            </LinkButton>
        </a>
    )
}
export function TwitterButton(props) {
    const { sx } = props
    return (
        <a href="https://www.twitter.com/uncoolcats">
            <LinkButton sx={sx}>
                    <LinkIcon src={TwitterLogoWhite}/>
            </LinkButton>
        </a>
    )
}