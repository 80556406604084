import React from 'react'
import { Grid, styled, useTheme } from '@mui/material'
import useMediaQuery from '@material-ui/core/useMediaQuery';



// Turns out Mui has their own impl of image list. Might be worth looking into that

const ImageFrame = styled(`img`)(({theme}) => ({
    borderRadius: '20px',
    border: `3px solid ${theme.palette.neutral.main}`,
    width: '218px',
    height: '218px',
    [theme.breakpoints.down('lg')]: {
        width: '189px',
        height: '189px',
    }
}))

export function ImageList(props) {
    const { lowerCount, images, imageKey, spacing, alignment } = props;
    const theme = useTheme();
    const largeScreen = useMediaQuery(() => theme.breakpoints.up('md'));
    return (
        <Grid container direction={largeScreen?"row":"column"} spacing={10} wrap="nowrap" columnSpacing={{ md: spacing }} rowSpacing={1} justifyContent={alignment} align='center'>
            {
                images.map((image, index) => {
                    const hide = !!lowerCount && index >= lowerCount ? 'none' : 'block';
                    return (
                        <Grid item xs={12} sm='auto' key={`${imageKey}_${index}`} sx={{
                            display: { xs: `${hide}`, md: 'block' },
                        }}>
                            <ImageFrame src={image}/>
                        </Grid>
                    )
                })
            }
        </Grid>
    )
}