import React from 'react'
import { Grid, Typography, useTheme, Box, styled } from '@mui/material'
import { DarkSection } from '../lib/DarkSection'
import UpperImage from '../../../static/img/diagonal-right.png';
import LowerImage from '../../../static/img/header-background.png';

const ImgContent = styled(`img`)(({theme}) => ({
    width: 'inherit',
    height: 'inherit',
    // marginTop: '-220px',
    marginLeft: '25px',
    [theme.breakpoints.down('lg')]: {
        marginTop: 0,
        marginLeft: 0,
    }
}))

const ImgContainer = styled(`div`)(({theme}) => ({
    width: '720px',
    height: '720px',
    [theme.breakpoints.down('lg')]: {
        margin: 'auto',
        width: '320px',
        height: '320px',
    }
}))

export function Pricing() {
    const theme = useTheme();
    return (
        <DarkSection
            upperImage={UpperImage}
            upperHeight='216px'
            lowerImage={LowerImage}
            lowerHeight='320px'
        >
            <Grid container sx={{
                paddingLeft: '144px',
                [theme.breakpoints.down('lg')]: {
                    paddingLeft: '32px',
                    paddingRight: '32px',
                    justifyContent: 'center',
                }
            }}>
                <Grid item xs={12} md={6}>
                    <Grid item xs={12}>
                        <Typography variant="h1">PRICING</Typography>
                    </Grid>
                    <Grid item xs={12} marginTop='24px'>
                        <Typography component={'div'} variant="h2">
                            <div>Give me your lunch money, dweeb! How much, how many?! !WEN?</div>
                        </Typography>
                    </Grid>
                    <Grid item xs={12}
                        sx={{
                            display: { xs: 'block', md: 'none' }
                        }}>
                        <ImgContainer>
                            <ImgContent src='img/price-cat.png'/>
                        </ImgContainer>
                    </Grid>
                    <Grid item xs={12} marginTop='40px'>
                        <Typography component={'div'} variant="body1">
                            <div>
                                The mint is now over! You can check out the uNcool cats at <a style={{textdecoration: "underline", color: "white"}} href="https://opensea.io/collection/uncool-cats-nft">OpenSea!</a> 
                            </div>
                        </Typography>
                        <Box height="60px"/>
                    </Grid>
                </Grid>
                <Grid item xs={6}
                    sx={{
                        display: { xs: 'none', md: 'block' }
                    }}
                >
                    <ImgContent src='img/price-cat.png'/>
                </Grid>
            </Grid>
        </DarkSection>
    )
}