import React from 'react'
import { styled, Card, CardHeader, CardContent, CardActions } from '@mui/material'
import { Link } from "react-router-dom";


const SectionContainer = styled(Card)(({theme}) => ({
    textAlign: 'left',
    borderRadius: '20px',
    boxShadow: '4px',
}))

const SectionHeader = styled(CardHeader)(({theme}) => ({
    color: 'black',
    background: `${theme.palette.secondary.main}`,
    border: `3px solid ${theme.palette.neutral.main}`,
    borderRadius: '20px 20px 0 0 ',
    paddingLeft: '40px',
    paddingTop: '20px',
    paddingBottom: '30px',
    textAlign: 'left',
    '& .MuiCardHeader-title': {
        fontFamily: 'Montserrat',
        fontSize: '25px',
        lineHeight: '30px',
        fontWeight: 700
    }
}))

const SectionBody = styled(CardContent)(({theme}) => ({
    background: `${theme.palette.neutral.main}`,
    paddingLeft: '40px',
    paddingTop: '48px',
    textAlign: 'left',
}))

const BodyItem = styled(`div`)(({theme}) => ({
    marginBottom: '20px',
    fontFamily: 'Montserrat',
    fontSize: '25px',
    lineHeight: '30px',
    fontWeight: 700
}))

const SectionActions = styled(CardActions)(({theme}) => ({
    background: `${theme.palette.neutral.main}`,
    paddingLeft: '40px',
    paddingTop: '30px',
    paddingBottom: '50px',
    textAlign: 'left',
}))

const ActionLink = styled(`div`)(({theme}) => ({
    borderBottom: `3px solid ${theme.palette.secondary.main}`,
    fontFamily: 'Special elite',
    fontSize: '25px',
    lineHeight: '25px',
    fontWeight: 400,
}))

export function FaqSectionContainer(props) {
    const { title, content, route } = props

    return (
        <SectionContainer>
            <SectionHeader title={title}></SectionHeader>
            <SectionBody>
                {content.map((item) => {
                    return (
                        <BodyItem key={item}>
                            {item}
                        </BodyItem>
                    )
                })}
            </SectionBody>
            <SectionActions>
                <Link to={route} style={{
                    textDecoration: 'none',
                    color: 'inherit',
                }}>
                    <ActionLink>
                        [SEE ALL]
                    </ActionLink>
                </Link>
            </SectionActions>
        </SectionContainer>
    )
}