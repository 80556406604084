import React from 'react';

import { Paper, Box } from '@mui/material';
import { MintIntro } from '../../components/Mint/MintIntro.jsx'

export default function Mint() {
    return (

        <Paper sx={{boxShadow: 0, zIndex: -2}}>
            <Box sx={{
                margin: 'auto',
                maxWidth: '2100px'

            }}>
                <MintIntro/>


            </Box>
        </Paper>
    )
}