import React, {useState, useEffect } from 'react'
import { Grid, Button, Typography, useTheme, styled, Box, Link } from '@mui/material'
import { DiscordButton, OpenSeasButton, TwitterButton } from '../SocialLinkButtons'; 
import { MintUsageBar } from '../Mint/MintUsageBar';

import {fetchTotalSupply } from "../lib/interact.jsx";

const Trash = styled(`img`)(({theme}) => ({
    position: 'absolute',
    zIndex: 1,
    bottom: '-50px',
    right: '15%',
    width: '578px',
    height: '365px',
    [theme.breakpoints.down('xl')]: {
        bottom: '-60px',
        transform: 'rotate(-6deg)',
    },
    [theme.breakpoints.down('lg')]: {
        right: '5%',
        bottom: '-80px',
        transform: 'rotate(-13deg)',
        width: '287px',
        height: '181px',
    },
    [theme.breakpoints.down('md')]: {
        bottom: '-100px',
        transform: 'rotate(-15deg)',
    },
}))

const Arm = styled(`img`)(({theme}) => ({
    position: 'absolute',
    left: 0,
    bottom: '60px',
    width: '249px',
    height: '145px',
    [theme.breakpoints.down('lg')]: {
        bottom: '5px',
        width: '120px',
        height: '70px',
    }
}))

export function HomeGreeting() {

    const theme = useTheme();
    const SocialStyling = { marginTop: '12px' }
    const [totalSupply, setTotalSupply] = useState(0);
    
    useEffect( async () => {
        let supplyMinted = await fetchTotalSupply()
        // console.log(supplyMinted);
        setTotalSupply(supplyMinted);
    }, [])

    return (
        <>
            <Grid container spacing={2} sx={{
                marginTop: '0px',
                paddingLeft: '120px',
                marginBottom: '200px',
                [theme.breakpoints.down('lg')]: {
                    paddingLeft: '24px',
                },
            }}>
                <Grid item xs={10} md={6}>
                    <Grid item xs={12} md={10}>
                        <Typography variant="h1">THE CATS YOU’VE NEVER HEARD OF</Typography>
                    </Grid>
                </Grid>
                <Grid item xs={10} md={7}>
                    <Typography variant="h2">Generating NFT Kitties Before It Was Cool</Typography>
                </Grid>
                
                <Grid item xs={12} marginLeft='5px' marginTop='-4px'>
                    <MintUsageBar counterFontSizeMd={'25px'} counterFontSize={'25px'} hideBar={true} remaining={-1*(totalSupply-6969)} total={6969}/>
                </Grid>
            </Grid>
            <div key="bgImages" style={{position: 'relative'}}>
                <Arm src='/img/hand-laughing.png'/>
                <Trash src='img/trash-can.png'/>
            </div>
            <Box sx={{
                position: 'absolute',
                justifyContent: 'center',
                display: { xs: 'none', md: 'flex' },
                justifyContent: 'center',
                flexDirection: 'column',
                right: '5%',
                top: '40%',
            }}>
                <DiscordButton sx={SocialStyling}/>
                <OpenSeasButton sx={SocialStyling}/>
                <TwitterButton sx={SocialStyling}/>
            </Box>
        </>
    )
}
