// const alchemyKey =  "https://eth-rinkeby.alchemyapi.io/v2/D6h0gqW8d5PZODrzj3uMNdbP5tPu7lNX" // TODO: Change this
const alchemyKey = "https://eth-mainnet.alchemyapi.io/v2/6SHCACss-ALbIn1kwsd8E-QKDDb9MF-p" // MAinnet
const { createAlchemyWeb3 } = require("@alch/alchemy-web3");
const web3 = createAlchemyWeb3(alchemyKey);

const contractABI = require('./contract-abi.json')
const contractAddress = "0x5F9E300108Fb156cFbE21C48A870876E97745af9"; // TODO: Check this is what you want!
const sourContract = "0x020eE035613f469Fd6F1ad4Ba29A5953209087DE"; // TODO: Check this is what you want!
const sourContractABI = require('./sour-contract-abi.json')

const eth=Math.pow(10,18)

const tokenAddress ='0x020eE035613f469Fd6F1ad4Ba29A5953209087DE';
const tokenSymbol = 'SOUR';
const tokenDecimals = 18;
const tokenImage = 'https://i.imgur.com/1KJyt1L.png';


export const addTokenToMeta = async() => {  
    if (navigator.userAgent.includes("Firefox")){
      console.log("tear my life into pieces")
      // handle unresolved promise
      ethereum.request({
        method: 'wallet_watchAsset',
        params: {
          type: 'ERC20', // Initially only supports ERC20, but eventually more!
          options: {
            address: tokenAddress, // The address that the token is at.
            symbol: tokenSymbol, // A ticker symbol or shorthand, up to 5 chars.
            decimals: tokenDecimals, // The number of decimals in the token
            image: tokenImage, // A string url of the token logo
          },
        },
      }).resolve("Please work").then(resolve => {
        console.log(resolve)
      })
    } else {
      // wasAdded is a boolean. Like any RPC method, an error may be thrown.
      ethereum.request({
        method: 'wallet_watchAsset',
        params: {
          type: 'ERC20', // Initially only supports ERC20, but eventually more!
          options: {
            address: tokenAddress, // The address that the token is at.
            symbol: tokenSymbol, // A ticker symbol or shorthand, up to 5 chars.
            decimals: tokenDecimals, // The number of decimals in the token
            image: tokenImage, // A string url of the token logo
          },
        },
      }).then(wasAdded => {
        if (wasAdded) {
          return {
            status: "$SOUR imported to wallet, please refresh!",
          }
        } else {
          return {
            status: "$SOUR not imported",
          }
        }
      });
    }
}

export const checkSourForId = async(tokenId) => {
  if (!window.sourContract){
    window.sourContract = await new web3.eth.Contract(sourContractABI.abi, sourContract);
  }
  let accruedSour = await window.sourContract.methods.accumulated(tokenId).call()
  // console.log("accumulated sour" , accruedSour)
  return accruedSour/eth
}

export const getWhitelistMintActive = async() => {  
    // TODO: do we need to reinstiate this every time?
    window.contract = await new web3.eth.Contract(contractABI.abi, contractAddress);
    return await window.contract.methods.isWhitelistActive().call();

}

export const getPublicMintActive = async() => {
    window.contract = await new web3.eth.Contract(contractABI.abi, contractAddress);
    return await window.contract.methods.publicMintActive().call();
}


export const fetchTokenIds = async() => {
  // console.log("checking tokenIds for " + window.ethereum.selectedAddress)
  window.contract = await new web3.eth.Contract(contractABI.abi, contractAddress);
	let tokenIds = [];
	let totalTokens = await window.contract.methods.balanceOf(window.ethereum.selectedAddress).call();
	for (let i = 0; i < totalTokens; i++ ){
		let token = await window.contract.methods.tokenOfOwnerByIndex(window.ethereum.selectedAddress, i).call()
		tokenIds.push(token)
	}
	return tokenIds

}

export const fetchAccruedSour = async(tokenIds) => {
  // console.log("checking accumulated for " + tokenIds.toString())
  if (!window.sourContract){
    window.sourContract = await new web3.eth.Contract(sourContractABI.abi, sourContract);
  }
  let accruedSour = await window.sourContract.methods.accumulatedMultiCheck(tokenIds).call()
  // console.log("accumulated sour" , accruedSour)
  return accruedSour/eth
}

export const fetchAccruedSourSingle = async(tokenId) => {
  // console.log("checking accumulated for " + tokenIds.toString())
  if (!window.sourContract){
    window.sourContract = await new web3.eth.Contract(sourContractABI.abi, sourContract);
  }
  let accruedSour = await window.sourContract.methods.accumulated(tokenId).call()
  // console.log("accumulated sour" , accruedSour)
  return accruedSour/eth
}


export const fetchSourBalance = async() => {
  // console.log("checking balance for " + window.ethereum.selectedAddress)
  if (!window.sourContract){
    window.sourContract = await new web3.eth.Contract(sourContractABI.abi, sourContract);
  }
    let balance = await window.sourContract.methods.balanceOf(window.ethereum.selectedAddress).call()
  // console.log("got balance" + balance)
    return balance/eth
}

export const fetchSourTotalSupply = async() => {
  // console.log("checking sour total supply")
  if (!window.sourContract){
    window.sourContract = await new web3.eth.Contract(sourContractABI.abi, sourContract);
  }
  let balance = await window.sourContract.methods.totalSupply().call()
  return balance/eth
}

export const claimSour = async(tokenIds) => {
    if (!window.sourContract){
      window.sourContract = await new web3.eth.Contract(sourContractABI.abi, sourContract);
    }
    // console.log("address: " + window.ethereum.selectedAddress)

    // console.log(tokenIds)

    const nonce = await web3.eth.getTransactionCount(window.ethereum.selectedAddress);
    let tx = {
          'from': window.ethereum.selectedAddress,
          'nonce' : nonce.toString(),
          'to': sourContract,
          'data': window.sourContract.methods.claim(tokenIds).encodeABI()
    };

    //sign transaction via Metamask
    try {
        const txHash = await window.ethereum
            .request({
                method: 'eth_sendTransaction',
                params: [tx],
            });
        return {
            success: true,
            status: "TX Submitted: check out your transaction on Etherscan", 
            txUrl: "https://etherscan.io/tx/" + txHash// TODO CHANGE THIS TO MAINNET
        }
    } catch (error) {
        return {
            success: false,
            status: error.message
        }
    }
}

export const fetchTokenURIs = async(tokenIds) => {
  // console.log("checking tokenIds for " + window.ethereum.selectedAddress)
  window.contract = await new web3.eth.Contract(contractABI.abi, contractAddress);
	let tokenURIs = [];
	for (let i = 0; i < tokenIds.length; i++ ) {
		let uri = await window.contract.methods.tokenURI(tokenIds[i]).call()
		let json = JSON.parse(atob(uri.substring(29)))
		tokenURIs.push(json)
	}
	return tokenURIs
}

export const fetchBaseURI = async() => {
  // console.log("getting base URI (hacky)")
  window.contract = await new web3.eth.Contract(contractABI.abi, contractAddress);
  let uri = await window.contract.methods.tokenURI(1).call()
  return uri.slice(7, -1)
}

export const fetchTotalSupply = async() => {
    // console.log("address: " + window.ethereum.selectedAddress)
  window.contract = await new web3.eth.Contract(contractABI.abi, contractAddress);
    let totalSupply = await window.contract.methods.totalSupply().call()
	return totalSupply
}

export const fetchNumAvailable = async() => {
    // console.log("address: " + window.ethereum.selectedAddress)
    window.contract = await new web3.eth.Contract(contractABI.abi, contractAddress);
    let numAvailable = await window.contract.methods.numAvailableToMint(window.ethereum.selectedAddress).call()
    return numAvailable
}


export const mintNFT = async(tokenCount, isWhiteList) => {
	if (!tokenCount) {
		tokenCount = 1;
	}

    window.contract = await new web3.eth.Contract(contractABI.abi, contractAddress);

	// await window.contract.methods.approve(contractAddress,web3.utils.toWei("1")).send({from:window.ethereum.selectedAddress});

    //set up your Ethereum transaction
    //the transaction
	const nonce = await web3.eth.getTransactionCount(window.ethereum.selectedAddress);
    let tx = {};
    /*
    const totalSupply = await fetchTotalSupply()
    if ( totalSupply + tokenCount  > 6969) {
        return {
            success: false,
            status: "Number of tokens exceeds amount available to mint." 
        }
    }
    */


    if (isWhiteList) {
        const numAvailable = await fetchNumAvailable()
        // console.log("num available: " + numAvailable)
        // console.log("tokenCount" + tokenCount)
        if (numAvailable < tokenCount) {
            return {
                success: false,
                status: "ERROR: You have " + numAvailable + " whitelist Uncool Cat(s) to mint." 
            }
        } 
        let price = await web3.eth.getGasPrice()
        tx = {
            'from': window.ethereum.selectedAddress,
            'nonce' : nonce.toString(),
            'to': contractAddress,
            'value': (parseInt(tokenCount) * 42000000000000000).toString(16),
            'data': window.contract.methods.mintWhitelist(tokenCount).encodeABI()
        };
    } else {
        let price = await web3.eth.getGasPrice()
        tx = {
            'from': window.ethereum.selectedAddress,
            'nonce' : nonce.toString(),
            'to': contractAddress,
            'value': (parseInt(tokenCount) * 42000000000000000).toString(16),
            'data': window.contract.methods.mint(tokenCount).encodeABI()
        };
    }

    //sign transaction via Metamask
    try {
        const txHash = await window.ethereum
            .request({
                method: 'eth_sendTransaction',
                params: [tx],
            });
        return {
            success: true,
            status: "TX Submitted: check out your transaction on Etherscan", 
            txUrl: "https://etherscan.io/tx/" + txHash// TODO CHANGE THIS TO MAINNET
        }
    } catch (error) {
        return {
            success: false,
            status: error.message
        }
    }

}
export const connectWallet = async () => {
    // console.log("I'm here" + JSON.stringify(window.ethereum.chainId))
  if (window.ethereum) {
    try {
      const addressArray = await window.ethereum.request({
        method: "eth_requestAccounts",
      });
      // console.log("walletconnected")
      const obj = {
        status: "Wallet Connected",
        address: addressArray[0],
      };
      return obj;
    } catch (err) {
      return {
        address: "",
        status: "😥 " + err.message,
      };
    }
  } else {
    return {
      address: "",
      status: "Please use Mainnet Ethereum",
    };
  }
}

export const getCurrentWalletConnected = async () => {
  if (window.ethereum) {
    if (window.ethereum.chainId !== "0x1") { // TODO: switch to Mainnet
        // mainnet = 0x1
        // rinkeby = 0x4

	return {
		address: "",
		status: "Please use Mainnet Ethereum Network", // TODO: Switch to this
		// status: "Please use Rinkeby Ethereum Network",
	}
    }
    try {
      const addressArray = await window.ethereum.request({
        method: "eth_accounts",
      });
      if (addressArray.length > 0) {
        return {
          address: addressArray[0],
          status: "Wallet Connected",
        };
      } else {
        return {
          address: "",
          status: "",
        };
      }
    } catch (err) {
      return {
        address: "",
        status: "😥 " + err.message,
      };
    }
  } else {
    return {
      address: "",
      status: "Please install Metamask",
    };
  }
};
