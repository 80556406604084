import React from 'react'
import { Grid, useTheme, Link, styled } from '@mui/material'
import { FaqQuestionContainer } from './FaqQuestionContainer'
import { FaqHomeButton } from './FaqHomeButton'
import { FaqTitle } from '../../components/Faq/FaqTitle';
import Figure from 'react-bootstrap/Figure';
import FigureImage  from  'react-bootstrap/FigureImage';
import FigureCaption  from 'react-bootstrap/FigureCaption';

const Image = styled(`img`)(({theme}) => ({
    width: '800px',
    // height: '451px',
    display: 'block',
    margin: 'auto',
    [theme.breakpoints.down('md')]: {
        width: '250px',
        height: 'auto',
    },
}))

export function FaqGeneral() {
    const theme = useTheme()
    return (
        <>
            <FaqTitle subTitle='Uncool Cats General Questions'></FaqTitle>
            <Grid container spacing={6} sx={{
                paddingLeft: '289px',
                paddingRight: '289px',
                paddingBottom: '90px',
                background: '#7285A5' ,
                [theme.breakpoints.down('md')]: {
                    // No design here, im yolo-ing
                    paddingLeft: '35px',
                    paddingRight: '35px',
                }
            }}>
                <Grid item xs={12}>
                    <FaqQuestionContainer title='What are Uncool Cats?'>
                        <div>
                            Uncool Cats is a collection of 6969 cats living on the Ethereum blockchain as ERC-721 non-fungible tokens (NFTs). 
                            <br/> <br/>
                            Unlike their cool counterparts, these cats have been through the wringer - stuffed in lockers, do the cool cats' homework, 
                            lack a skincare routine, stood up at prom and otherwise are down on their luck! 
                            <br/> <br/>
                            Don't pet these cats, they haven't showered in weeks! 
                            <br/> <br/>
                            In an unjust world, we can’t all be cool, right? Get a glass of sour milk and read on! 
                        </div>
                    </FaqQuestionContainer>
                </Grid>
                
                <Grid item xs={12}>
                    <FaqQuestionContainer title='What are the official contract addresses?'>
                        <div>
                            &ensp;&#183;&ensp;Uncool $UNCOOL Contract: 
                            <Link href='https://etherscan.io/address/0x5f9e300108fb156cfbe21c48a870876e97745af9' target='_blank'> etherscan.io</Link> &ensp;
                            <br/> <br/>
                        </div>
                        <div>
                            &ensp;&#183;&ensp;$SOUR Token Contract: 
                            <Link href='https://etherscan.io' target='_blank'> etherscan.io</Link> &ensp;

                            [Link available closer to mint date]
                            <br/> <br/>
                        </div>
                        
                    </FaqQuestionContainer>
                </Grid>
                <Grid item xs={12}>
                    <FaqQuestionContainer title='What are the Uncool Cats official links?'>
                        <div>
                            &ensp;&#183;&ensp;Website: <Link href='https://uncoolcats.com' target='_blank'>uncoolcats.com</Link>
                        </div>
                        <div>
                            &ensp;&#183;&ensp;Official OpenSea Collection: <Link href='https://opensea.io/collection/uncool-cats-nft'>https://opensea.io/collection/uncool-cats-nft</Link>
                        </div>
                        <div>
                            &ensp;&#183;&ensp;Twitter: <Link href='https://twitter.com/uncoolcats'>https://twitter.com/uncoolcats</Link>
                        </div>
                        <div>
                            &ensp;&#183;&ensp;Discord: <Link href='https://discord.gg/uncoolcats'>https://discord.gg/uncoolcats</Link>
                        </div>
                        <div>
                            &ensp;&#183;&ensp;Medium: <Link href='https://medium.com/@uncoolcats'>https://medium.com/@uncoolcats</Link>
                        </div>
                    </FaqQuestionContainer>
                </Grid>
                <Grid item xs={12}>
                    <FaqQuestionContainer title='What can I do with my Uncool Cats?'>
                        <div>
                            It’s yours. You own it and are free to use it as you like. Uncool Cats is a fun, non-speculative PFP project to show off your uncool side!
                            <br/> <br/>
                            The Uncool Cats community is more solid than Uncool Cats’ exam scores. 
                            We are true believers in good art!
                            <br/> <br/>
                            Uncool Cats will passively yield $SOUR tokens from holding your cat - and you’re really 
                            going to want those tokens for what we have planned! 
                            <br/> <br/>
                            Read more on that below and through our 
                            website pages to get all the details. The Uncool Cats will be a community driven project!
                            <br/> <br/>
                        </div>
                    </FaqQuestionContainer>
                </Grid>
                
                <Grid item xs={12}>
                    <FaqQuestionContainer title='What are the terms and conditions?'>
                        <div>
                        <Image src='/img/terms.png'/>
                        </div>
                        <div>
                        <Image src='/img/terms2.png'/>
                        </div>
                    </FaqQuestionContainer>
                </Grid>
                <Grid item xs={12}>
                    <FaqQuestionContainer title="What is the team's take on metadata and images?">
                        <div>
                            Before reveal, the collection preview metadata is stored on a temporary private server.
                        </div>
                        <br/>
                        <div>
                            Upon reveal, metadata &amp; images will have been randomized and uploaded to IPFS.
                        </div>
                        <br/>
                        <div>
                            Post-sale, we will ensure further decentralization by freezing the metadata on OpenSea.
                        </div>
                    </FaqQuestionContainer>
                </Grid>
                <Grid item xs={12} textAlign='center' sx={{
                    marginTop: '60px'
                }}>
                    <FaqHomeButton/>
                </Grid>
            </Grid>
        </>
    )
}