import React from 'react'
import { Grid, Typography, useTheme, Box } from '@mui/material'
import { CatGallerySection } from './CatGallerySection'
import { CatGalleryCaptionedSection } from './CatGalleryCaptionedSection'

const common = [
    '/img/cats/uncool/uncool1.png',
    '/img/cats/uncool/uncool2.png',
    '/img/cats/uncool/uncool3.png',
    '/img/cats/uncool/uncool4.png',
    '/img/cats/uncool/uncool5.png',
]

const rare = [
    '/img/cats/rare/rare1.png',
    '/img/cats/rare/rare5.png',
    '/img/cats/rare/rare2.png',
    '/img/cats/rare/rare4.png',
    '/img/cats/rare/rare3.png',
]

const legendary = [
    '/img/cats/legendary/legendary1.GIF',
    '/img/cats/legendary/legendary2.GIF',
    '/img/cats/legendary/legendary3.GIF',
    '/img/cats/legendary/legendary4.GIF',
    '/img/cats/legendary/legendary5.GIF',
    '/img/cats/legendary/legendary6.PNG',
    '/img/cats/legendary/legendary7.PNG',
    '/img/cats/legendary/legendary8.PNG',
    '/img/cats/legendary/legendary9.PNG',
    '/img/cats/legendary/legendary10.PNG',
]

const legendaryNames = [
    'Clockwork',
    'Space Explorer',
    'Robocat',
    'Feels Rainy Man',
    'Northern Lights',
    'Squiggles',
    'Broken Angel',
    'Warped',
    'Mona Lisa',
    'Island Boi'
]

export function CatGallery() {
    const theme = useTheme()

    return (
        <Grid container sx={{
            marginTop: '64px',
            paddingLeft: '144px',
            [theme.breakpoints.down('lg')]: {
                paddingLeft: '32px',
                paddingRight: '32px',
                justifyContent: 'left',
            }
        }}>
            <Grid item xs={12} md={7}>
                <Typography alignment='flex-start' variant="h1">
                    How Uncool Are These Genetic Lottery Losers? Come See! 
                </Typography>
            </Grid>
            <Grid item xs={12} md={7} marginTop='24px'>
                <Typography component={'div'} variant="h2">
                    These Uncool Cats are programmatically generated with randomized traits with a range of rarity. 
                </Typography>
            </Grid>
            <Grid item xs={12} md={10} marginTop='32px' >
                <CatGallerySection
                    header={'The Classic Uncool Cats'}
                    body={'While these aren’t extremely rare they’re so Uncool it hurts.'}
                    lowerCount={3}
                    images={common}
                    alignment='flex-start'
                    imageKey={'legendary'}
                />
                <Box height='60px'/>
            </Grid>
            <Grid item xs={12} md={10} marginTop='32px' >
                <CatGallerySection
                    header={'Feeling lucky punk? Rarer than an Uncool Cat getting asked out on a date!'}
                    body={'Some Uncool Cats come with some very rare traits, like these ones below.'}
                    lowerCount={3}
                    images={rare}
                    alignment='flex-start'
                    imageKey={'legendary'}
                />
                <Box height='60px'/>
            </Grid>
            <Grid item xs={12} md={10} marginTop='32px' >
                <CatGalleryCaptionedSection
                    header={`Jeff Bezos was Uncool, but now he's a billionaire. These former dorks made it out the kitty litter and are living legends on the blockchain.`}
                    body={'Only 20 legendary Uncool Cats exist in the entire collection (0.287% chance to pull one!). May the odds be in your favor.'}
                    lowerCount={3}
                    images={legendary}
                    alignment='flex-start'
                    imageKey={'legendary'}
                    captionHeading={legendaryNames}
                />
                <Box height='60px'/>
            </Grid>

        </Grid>
    )
}

