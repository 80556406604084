import React from 'react'
import { Paper, Grid, Typography, Button, styled, useTheme, SvgIcon } from '@mui/material'
import { Link } from 'react-router-dom'
import MailLogoWhite  from '../icons/mail.png';
import DiscordLogoWhite  from '../icons/discord.png';
import OpenSeaLogoWhite  from '../icons/cube.png';
import TwitterLogoWhite  from '../icons/twitter.png';

const FooterWrapper = styled(`div`)(({theme}) => ({
    background: theme.palette.neutral.main,
    maxWidth: '2100px',
    margin: 'auto',
    paddingTop: '34px',
    paddingBottom: '24px'
}))

const FooterIcon = styled(`img`)(({theme}) => ({
    width:'39px',
    height: '39px',
    marginTop: '4px',
    marginLeft: '5px',
    marginRight: '5px',
    [theme.breakpoints.down('md')]: {
        width: '20px',
        height: 'auto',
    },
}))

export function Footer() {

    const theme = useTheme()

    return (
        <Paper>
            <FooterWrapper>
                <Grid
                    container
                    justifyContent='space-evenly'
                    alignItems='center'
                    textAlign='center'
                >
                    <Grid item xs={4}>
                        <Typography variant='h2' sx={{
                            fontSize:'18px',
                            lineHeight:'18px',
                            [theme.breakpoints.down('md')]: {
                                fontSize:'12px',
                                lineHeight:'12px',
                            }
                        }}>uNcool Cats 2021</Typography>
                    </Grid>
                    <Grid item xs={4}>
                            <Button onClick={() => window.scrollTo(0, 0)} sx={{
                                borderRadius: '50%',
                                // I literally have no clue why just using width doesn't work
                                maxWidth: '40px',
                                minWidth: '40px',
                                height: '40px',
                                padding: 0,
                                alignItems: 'center',
                            }}>
                                <img src='/img/logo-large.png' style={{
                                    width:'39px',
                                    height: '39px',
                                    paddingTop: '4px',
                                    [theme.breakpoints.down('md')]: {
                                        width: '2px',
                                        height: 'auto',
                                    },
                                }}/>
                            </Button>
                    </Grid>
                    <Grid item xs={4}>
                        <a href="mailto:hello@uncoolcats.com">
                                <FooterIcon src={MailLogoWhite}/>
                        </a>
                        <a href="https://www.discord.gg/uncoolcats">
                                <FooterIcon src={DiscordLogoWhite}/>
                        </a>
                        <a href="https://opensea.io/collection/uncool-cats-nft">
                                <FooterIcon src={OpenSeaLogoWhite}/>
                        </a>
                        <a href="https://www.twitter.com/uncoolcats">
                                <FooterIcon src={TwitterLogoWhite}/>
                        </a>
                    </Grid>
                </Grid>

            </FooterWrapper>
            <FooterWrapper>
                <Grid
                    container
                    justifyContent='space-evenly'
                    alignItems='center'
                    textAlign='center'
                >
                    <Grid item xs={12}>
                        <Typography variant='h2' sx={{
                            fontSize:'10px',
                            lineHeight:'18px',
                            textDecoration: 'none',
                            color: 'gray',
                            [theme.breakpoints.down('md')]: {
                                fontSize:'12px',
                                lineHeight:'12px',
                            }
                        }}><Link style={{textDecoration: "none", color: "gray"}} to="/terms">Terms And Conditions</Link></Typography>
                    </Grid>
                </Grid>

            </FooterWrapper>
        </Paper>
    )
}