import React, {useState, useEffect} from 'react'

import { Grid, Typography, styled, useTheme, Button, Link } from '@mui/material'
import { flexbox } from '@mui/system';

const sourContractEtherscan = "https://etherscan.io/address/0x020eE035613f469Fd6F1ad4Ba29A5953209087DE" // TODO: ADD ME

const Panel = styled(`div`)(({theme}) => ({
    width: "650px",
    height: "277px",

    display: "flex",
    flexDirection: "column",
    alignItems: "center",

    background: "#232323",
    border: "3px solid #000000",
    boxSizing: "border-box",
    borderRadius: "20px",
    [theme.breakpoints.down('md')]: {
        width: '300px',
        height: '300px',
        marginTop: '30px'
    },
}))

export function SourContract(props) {
    const theme = useTheme();

    const emissionStartTs = 1638038112;
    const showContract = true;

    const [sourSupply, setSupply ] =  useState("...");

    const numberWithCommas = (value, decimal) => {
        return parseFloat(parseFloat(value).toFixed(decimal)).toLocaleString(
        "en-IN",
        {
            useGrouping: true,
        }
        );
    };

    useEffect( async () => {
        let myInterval = setInterval(() => {
            let currentTs=  Math.floor(+new Date() / 1000) //get current timestamp
            setSupply(
                    ((currentTs-emissionStartTs)*6969*0.00011574074 ).toLocaleString('en')
            ); 
        })
            
        return ()=> {
            clearInterval(myInterval);
        };
    }, [props.sourSupply])

    return (
        <Panel>
            <div style={{margin:"auto"}}>
            <Typography marginBottom="18px" variant="h3">Total $SOUR Supply: {sourSupply} </Typography>
            <Typography marginBottom="18px" variant="h3">$SOUR Contract:</Typography>
            <Typography marginBottom="18px" variant="h3"><br/></Typography> {/*newline just to make it look nicer */}
            { showContract ? 
                <Link style={{textDecoration: "none !important" }} href={sourContractEtherscan} target="_blank" >
                    <Button sx={{
                        marginTop: '25px',
                        border: `5px solid ${theme.palette.neutral.main}`,
                        // marginTop: '-125px',
                        [theme.breakpoints.down('lg')]: {
                            // marginTop: '25px',
                        }
                    }}>
                        <span style={{textDecoration: "none !important"}}> ETHERSCAN </span>
                    </Button>  
                </Link>: 
            <Button sx={{
                marginTop: '25px',
                border: `5px solid ${theme.palette.neutral.main}`,
                // marginTop: '-125px',
                [theme.breakpoints.down('lg')]: {
                    marginTop: '25px',
                }
            }}>
                COMING SOON
            </Button> }
            </div>
        </Panel>
    )
}