import {createTheme} from '@mui/material';

const theme = createTheme({
    palette: {
        primary: {
            main: "#FFFFFF" // white
        },
        secondary: {
            main: "#FEEA7F" // Yellow thing
        },
        neutral: {
            main: "#262424" // dark grey
        },
        text: {
            primary: "#FFFFFF",
            secondary: "#000000",
        },
        background: {
            default: '#7285A5' // Blue
        }
    },
    typography: {
        h1: {
            fontFamily: 'Special elite',
            fontSize: '30px',
            fontWeight: 400
        },
    }
})

theme.components = {
    MuiButton: {
        defaultProps: {
            variant: "contained",
        },
        styleOverrides: {
            contained: {
                boxShadow: 'none'
            },
            root: {
                borderRadius: '60px',
                border: `5px solid ${theme.palette.primary.main}`,
                textTransform: 'unset',
                whiteSpace: 'noWrap',
                transitionProperty: 'background-color, box-shadow, border, color',
                background: theme.palette.secondary.main,
                fontFamily: 'var(--font-family-proxima_soft-extrabold)',
                lineHeight: '25px',
                fontSize: '25px',
                fontWeight: 800,
                [theme.breakpoints.down('lg')]: {
                    // TODO: Change button text padding
                    fontSize: '14px',
                    lineHeight: '14px', // Smaller font size to make somehow less jank page resizing
                },
            },
        }
    },
    MuiPaper: {
        styleOverrides: {
            root: {
                borderRadius: 0,
                background: theme.palette.background.default,
            }
        }
    },
    MuiTypography: {
        styleOverrides: {
            h1: {
                fontFamily: 'var(--font-family-proxima_soft-extrabold)',
                fontSize: '70px',
                lineHeight: '70px',
                fontWeight: 800,
                [theme.breakpoints.down('lg')]: {
                    fontSize: '35px',
                    lineHeight: '35px',
                }
            },
            h2: {
                fontFamily: 'Special elite',
                fontSize: '30px',
                lineHeight: '30px',
                fontWeight: 400,
                [theme.breakpoints.down('lg')]: {
                    fontSize: '20px',
                    lineHeight: '20px',                  
                }
            },
            h3: {
                fontFamily: 'Special elite',
                fontSize: '22px',
                lineHeight: '21.94px',
                fontWeight: 800,
                [theme.breakpoints.down('lg')]: {
                    fontSize: '20px',
                    lineHeight: '20px',                  
                }
            },

            body1: {
                fontFamily: 'Montserrat',
                fontSize: '18px',
                lineHeight: '21.94px',
                fontWeight: 400,
            },
            body2: {
                fontFamily: 'Montserrat',
                fontSize: '18px',
                lineHeight: '21.94px',
                fontWeight: 800,
            },
        }
    },
    MuiTab: {
        styleOverrides: {
            root: {
                padding: 0,
                marginRight: 'auto',
                color: theme.palette.text.primary,
                fontSize: '25px',
                lineHeight: '25px',
                fontWeight: '800',
                fontFamily: 'var(--font-family-proxima_soft-extrabold)',
                [theme.breakpoints.down('lg')]: {
                    fontSize: '14px',
                    lineHeight: '14px',
                }
            },
        }
    },
    MuiTabs: {
        defaultProps: {
            indicatorColor: 'secondary',
            textColor: 'primary'
        },
        styleOverrides: {
            indicator: {
                width: '100%',
                textAlign: 'center'
            }
        }
    },
    MuiLink: {
        styleOverrides: {
            root: {
                color: `${theme.palette.secondary.main}`
            }
        }
    }
}

export default theme