import React from 'react'
import { Grid, useTheme } from '@mui/material'
import { FaqSectionContainer } from './FaqSectionContainer'
import { FaqTitle } from '../../components/Faq/FaqTitle';

export function FaqHome() {

    const theme = useTheme()

    const generalQs = [
        'What are Uncool Cats?',
        'What are the official contract addresses?',
        'What are the Uncool Cats official links?',
        'What can I do with my Uncool Cats?',
        'What are the terms and conditions?',
        `What is the team's take on metadata and images?`,
    ]
    
    const sourQs = [
        'What exactly is $SOUR?',
        'What is the $SOUR distribution timeline?',
        'How do I yield $SOUR?',
    ]

    const licenseQs = [
        'What is the teams stance on licensing/derivative work commercial right issues from "Cool Cats"?',
        'What about OpenSea verification?',
    ]

    const ratRaceQs = [
        `What are Teacher's Pets - $PETS?`,
    ]

    const voteQs = [
        'Uncool Cats Voting? How does it work?',
    ]

    // Should store all text inside one struct, and map that struct to make grid rows and container
    // But because of images that becomes less straight-forward, so we do this manual stuff

    return (
        <>
            <FaqTitle subTitle='There Is No Such Thing As A Stupid Question'></FaqTitle>
            <Grid container spacing={10} sx={{
                paddingLeft: '289px',
                paddingRight: '289px',
                paddingBottom: '90px',
                [theme.breakpoints.down('md')]: {
                    // No design here, im yolo-ing
                    paddingLeft: '35px',
                    paddingRight: '35px',
                }
            }}>
                <Grid item xs={12}>
                    <FaqSectionContainer title='Uncool Cats General' content={generalQs} route='/faq-general-info'/>
                </Grid>
                <Grid item xs={12}>
                    <FaqSectionContainer title='$SOUR' content={sourQs} route='/faq-sour'/>
                </Grid>
                <Grid item xs={12}>
                    <FaqSectionContainer title='Licensing &amp; Verification' content={licenseQs} route='/faq-license'/>
                </Grid>
            </Grid>
        </>
    )
}