import React from 'react';

import { Paper, Box } from '@mui/material';
import { WalletIntro } from '../../components/Wallet/WalletIntro.jsx'

export default function Wallet() {
    return (

        <Paper sx={{boxShadow: 0, zIndex: -2}}>
            <Box sx={{
                margin: 'auto',
                maxWidth: '2100px'

            }}>
                <WalletIntro/>


            </Box>
        </Paper>
    )
}