import React from 'react'
import { styled } from '@mui/material'


// These dimensions and offsets were done by hand, small changes can really screw it up
// Change carefully
const TvFrame = styled(`img`)(({theme}) => ({
    position:'absolute',
    width: 'inherit',
    height: 'inherit',
    zIndex: 1,
    [theme.breakpoints.down('lg')]: {
        marginLeft: '0',
    }
}))

const TvContent = styled(`img`)(({theme}) => ({
    position: 'absolute',
    marginLeft: '117px',
    marginTop: '82px', //old 75
    width: '345px',
    height: '345px',
    [theme.breakpoints.down('lg')]: {
        marginLeft: '59px',
        marginTop: '40px', //old 35
        width: '174px',
        height: '174px',
    }
}))

const Container = styled(`div`)(({theme}) => ({
    position: 'static',
    width: '585px',
    height: '585px',
    [theme.breakpoints.down('lg')]: {
        width: '295px',
        height: '295px',
        margin: 'auto',
        marginTop: '60px',
    }
}))


export function TvImage(props) {
    const { frame, content } = props
    return (
    <Container>
        <TvContent src={content}/>
        <TvFrame src={frame}/>
    </Container>
    )
}