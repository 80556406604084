import React from 'react';

import { Paper, Box } from '@mui/material';
import { SourIntro } from '../../components/Sour/SourIntro.jsx'

export default function Sour() {
    return (

        <Paper sx={{boxShadow: 0, zIndex: -2}}>
            <Box sx={{
                margin: 'auto',
                maxWidth: '2100px'

            }}>
                <SourIntro/>


            </Box>
        </Paper>
    )
}