import React, {useEffect, useState} from 'react'
import { Grid, Typography, styled, useTheme, Box, Button } from '@mui/material'
import {SourItem} from './SourItem';
import {SourPanel} from './SourPanel';
import {SourContract} from './SourContract';
import { connectWallet, fetchSourTotalSupply, getCurrentWalletConnected, fetchTokenIds, fetchAccruedSour, fetchSourBalance, checkSourForId } from "../lib/interact.jsx";
import { Warning } from '../lib/Warning';
import InputGroup from 'react-bootstrap/InputGroup';
import FormControl from 'react-bootstrap/FormControl';

const Image = styled(`img`)(({theme}) => ({
    width: '451px',
    height: '451px',
    display: 'block',
    margin: 'auto',
    [theme.breakpoints.down('md')]: {
        width: '200px',
        height: 'auto',
    },
}))

const StyledPanels = styled(`div`)(({theme}) => ({
gap:"50px",
width:"800px",
margin:"auto",
display:"flex", 
flexDirection:'row',
    [theme.breakpoints.down('md')]: {
        width:"300px",
        flexDirection: 'column',
    },

}))

const imgs = [
    '/img/teacherspet.png',
    '/img/logo_whitetee.png',
    '/img/sourmilk.png',
    '/img/milk-transp.png',
    '/img/vote.png'
]

const items = [
"Used to mint Teacher's Pets rats which can ONLY be purchased with $SOUR",
"Limited edition merchandise drops purchasable with $SOUR from community effort",
"The 'Rat Race' game wager token",
"1 $SOUR = 1 $SOUR - While it is the ERC-20 token that forms the basis of the Uncool Cats ecosystem, it should not be viewed as an investment nor a storage of economic value.",
];

export function SourIntro() {
    const [walletAddress, setWallet] = useState("");
    const [tokenIds, setTokenIds] = useState([]);
    const [accruedSour, setAccruedSour] = useState("...");
    const [sourBalance, setSourBalance] = useState("...");
    const [sourSupply, setSourSupply] = useState("...");
    const [status, setStatus] = useState("...");
    const [txUrl, setTxUrl] = useState("")
    const [singleCheck, setSingleCheck] = useState("...");

    function addWalletListener() {
        // console.log("adding listener")
        if (window.ethereum.chainId) {
            // console.log("eth detected")
            if (window.ethereum.chainId !== "0x1") {
                // console.log("Mainnet network not found, found chain " + window.ethereum.chainId)
            } else {
                // console.log("adding listener")
                window.ethereum.on("accountsChanged", (accounts) => {
                    // console.log("Account changed: " + accounts)
                    if (accounts.length > 0) {
                        // callback hell
                        setWallet(accounts[0]);
                        setStatus("Wallet Connected");
                        // console.log("accountschanged triggered")
                        fetchTokenIds().then(accountTokens => {
                            // console.log("tokenIds" + accountTokens);
                            setTokenIds(accountTokens);
                            fetchAccruedSour(accountTokens).then(pendingSour => {
                                // console.log("pendingSour"  + pendingSour);
                                setAccruedSour(pendingSour);
                                fetchSourBalance().then( userSourBalance => {
                                    // console.log("sourBalance" +userSourBalance);
                                    setSourBalance(userSourBalance)
                                    fetchSourTotalSupply().then( sourTotalSupply => {
                                        // console.log("sourTotalSupply" + sourTotalSupply);
                                        setSourSupply(sourTotalSupply)
                                    })
                                });
                            })
                        })
                    } else {
                        setWallet("");
                        setStatus("No accounts connected");
                    }
                });
            }
        } else {
                // console.log("couldn't add listener")
            setStatus("Please connect to Metamask");
        }
    }

    const onCheckSour = async () => {
        setSingleCheck("Loading...")
        // console.log("Checking sour")
        // console.log( document.getElementById('textbox_id').value)
        let sour = await checkSourForId( document.getElementById('textbox_id').value)
        setSingleCheck(Number(Math.round(sour+'e3')+'e-3') )
        // console.log(sour)
    }

    useEffect( async () => {
        document.onreadystatechange = async () => {
            let status = await connectWallet();
            // console.log("connect wallet status " + JSON.stringify(status) )

            getCurrentWalletConnected().then( (resp) => {
                setStatus(resp.status);
                if (resp.address) {
                    setWallet(resp.address);
                } 
            });

            if (status.address !== "") {
                setWallet(status.address);
                let accountTokens = await fetchTokenIds();
                // console.log("tokenIds" + accountTokens);
                setTokenIds(accountTokens);
                let pendingSour = await fetchAccruedSour(accountTokens);
                setAccruedSour(pendingSour)
                // console.log("pendingSour"  + pendingSour);
                let userSourBalance = await fetchSourBalance();
                setSourBalance(userSourBalance)
                // console.log("sourBalance" +userSourBalance);
                let sourTotalSupply = await fetchSourTotalSupply();
                setSourSupply(sourTotalSupply)
                // console.log("sourTotalSupply" + sourTotalSupply);
            }

            addWalletListener();
        }
    }, [walletAddress, singleCheck]);
    
    const theme = useTheme();

    return (
        <Grid container direction='row-reverse' sx={{
            paddingRight: '344px',
            paddingLeft: '344px',
            paddingBottom: '344px',
            justifyContent: 'center',
            [theme.breakpoints.down('lg')]: {
                paddingLeft: '32px',
                paddingRight: '32px',
                justifyContent: 'center',
                margin: 'auto',
                textAlign: 'center',
            }
        }}>
            <Grid item xs={12} md={12} justifyContent='center' sx={{
                textAlign: 'center',
                margin: 'auto',
                [theme.breakpoints.down('lg')]: {
                    textAlign: 'center',
                }
            }}>

                <Grid item xs={13}>
                    <Typography variant="h1">Sour</Typography>
                </Grid>
                <Grid item xs={12} marginTop='24px'>
                    <Typography variant="h2">
                        Yield and chill. Have a cup of sour milk.
                    </Typography>
                </Grid>

                <Grid item xs={12} alignItems="center" marginTop='32px' direction="column" justifyContent='center' textAlign='center' >
                    {status !== "" && <Warning url={txUrl} label={status} />}
                </Grid>

                <Grid item xs={12} alignItems="center" marginTop='32px' direction="column" justifyContent='center' textAlign='center' >
                    {/* change flex to column on breakpoint */}
                    <StyledPanels>
                        <SourPanel showClaim={false}  wallet={walletAddress} tokenIds={tokenIds} sourBalance={sourBalance} pendingSour={accruedSour}/>
                        <SourContract sourSupply={sourSupply}/>
                    </StyledPanels>
                </Grid>
                <Grid item xs={12} alignItems="center" marginTop='32px' direction="column" justifyContent='center' textAlign='center' >

                    <Typography marginBottom="18px"variant="h3">Pending $SOUR for ID: {singleCheck}</Typography>
                    <InputGroup className="mb-3">
                        <FormControl
                        style={{  borderRadius:'5%',height:'50px', marginRight:'18px', fontSize:'20px', fontFamily:'Special Elite'}}
                        id="textbox_id"
                        placeholder="Uncool Cat Id"
                        aria-label="Uncool Cat Id"
                        aria-describedby="basic-addon2"
                        />
                        {/* Chad get by id */}
                        <Button onClick={() => onCheckSour()} sx={{ [theme.breakpoints.down('md')]: {marginTop: '15px'}, border: `5px solid ${theme.palette.neutral.main}`}} id="button-addon2">
                        Check $SOUR for ID 
                        </Button>
                    </InputGroup>
                </Grid>



                <Grid item xs={12} marginTop='24px'>
                    <Typography component={'div'} variant="body1">
                    <Grid item xs={12} marginTop='24px' textAlign='center'>
                        <Image src='img/lunch_money.PNG' />
                    </Grid>
                    </Typography>
                </Grid>

            </Grid>
        </Grid>
    )
}