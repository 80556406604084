import React from 'react'
import { Typography, Grid, styled, useTheme } from '@mui/material'

const Image = styled(`img`)(({theme}) => ({
    width: '451px',
    height: '451px',
    display: 'block',
    margin: 'auto',
    [theme.breakpoints.down('md')]: {
        width: '200px',
        height: 'auto',
    },
}))

export function FaqTitle(props) {
    const { subTitle } = props

    const theme = useTheme()

    return (
        <Grid container sx={{background:'#7285A5', margin: 0}} textAlign='center' >
            <Grid item xs={12}>
                <Typography variant="h1">FAQ</Typography>
            </Grid>
            <Grid item xs={12} marginTop='16px'>
                <Typography variant="h2">{subTitle}</Typography>
            </Grid>
            <Grid item xs={12} marginTop='46px'>
                <Image src='/img/faq-cat.png'/>
            </Grid>
        </Grid>
    )
}