import React, {useState, useEffect} from 'react'
import { styled } from '@mui/material'

const Wrapper = styled('div')(({theme}) => ({
    borderRadius: '20px',
    border: `3px solid #000000`,
    margin: 'auto',
    background: `${theme.palette.neutral.main}`,
    color: '#fff',
    maxWidth: '680px',
    fontSize: '30px !important',
    [theme.breakpoints.down('md')]: {
        maxWidth: '312px',
        fontSize: '20px !important',
    },
}))

const Content = styled('div')(({theme}) => ({
    padding: '24px',
    fontSize: '30px',
    fontWeight: 700,
    fontFamily: 'var(--font-family-proxima_soft-extrabold)',
    [theme.breakpoints.down('md')]: {
        fontSize: '20px',
        // padding: '38px',
        paddingLeft: '38px',
        paddingRight: '38px',
        paddingTop: '14px',
        paddingBottom: '14px',
    },
}))


export function Warning(props) {
    const [url, setUrl] = useState(props.label);
    const [label, setLabel] = useState(props.url);

    useEffect( () => {
        // console.log("label updated, :  " + props.label)
        // console.log("url updated, :  " + props.url)
        setUrl(props.url)
        setLabel(props.label);
    }, [props.url, props.label])

    return (
        <Wrapper>
            <Content variant="h1">
                {label}
                {url ? truncateURL(url) : ""}
            </Content>
        </Wrapper>
    )
}

// helper fn
function truncateURL(url)  {
    // console.log(url)
    return <a href={url}> {url.slice(0,33)}...{url.slice(-5)}</a>
}
