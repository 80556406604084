import React from 'react'
import { Grid, Typography, useTheme } from '@mui/material'
import { ImageList } from '../lib/ImageList'

export function CatGallerySection(props) {

    const { header, body, lowerCount, images, imageKey } = props;
    const theme = useTheme();

    return (
        <Grid container>
            <Grid item xs={12} md={7}>
                <Typography variant="h2">
                    {header}
                </Typography>
            </Grid>
            <Grid item xs={12} md={7} sx={{
                marginTop:'24px',
                marginBottom: '48px',
                [theme.breakpoints.down('lg')]: {
                    marginBottom: '32px',
                }
            }}>
                <Typography component={'div'} variant="body1">
                    {body} 
                </Typography>
            </Grid>
            <ImageList lowerCount={lowerCount} spacing={1} images={images} imageKey={imageKey}/>
        </Grid>
    )
}

