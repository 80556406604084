import React, { useState } from 'react';
import { Box, styled, Button, Grid, Paper, Hidden, SvgIcon } from '@mui/material';
import { HeaderSandwich } from './HeaderSandwich';
import { HeaderTabs } from './HeaderTabs';
import Image from '../../static/img/header-background.png';
import { BarIcon } from '../icons/bar-icon';
import { WallPaper } from './lib/WallPaper';

const HomeBox = styled(Box)(({theme}) => ({
    // Double check alignment of logo
    // Difficult to do due to weird png dimensions
    textAlign: 'left',
    paddingTop: '12px',
    '& > *': {
        marginLeft: '16px'
    },
    [theme.breakpoints.down('lg')]: {
        paddingTop: '4px',
        '& > *': {
            marginLeft: '8',
        }
    }
}))

const LogoBox = styled(`img`)(({theme}) => ({
    textAlign: 'center',
    background: theme.palette.secondary.main,
    borderRadius: '50%',
    border: `5px solid ${theme.palette.primary.main}`,
    width: '70px',
    height: '70px',
    paddingTop: '4px',
    [theme.breakpoints.down('lg')]: {
        width: '39px',
        height: '39px',
    }
}))

const LogoTextBox = styled(`img`)(({theme}) => ({
    textAlign: 'center',
    width: '306px',
    height: '71px',
    marginLeft: '-2px',
    [theme.breakpoints.down('lg')]: {
        marginLeft: '0',
        width: '140px',
        height: '32px',
    }
}))

export function Header() {

    const [showSandwhich, setShowSandwhich] = useState(false)

    return (
        <Paper>
            <WallPaper height='320px' image={Image} sx={{ margin:'auto', paddingTop: '4px'}}>
                <Grid container alignItems='center' maxWidth='2100px'>
                    <Grid item xs='auto'>
                        <a href='/'>
                            <HomeBox>
                                <LogoBox src='/img/logo-large.png'/>
                                <LogoTextBox src={'/img/whitelogotext-large.png'}/>
                            </HomeBox>
                        </a>
                    </Grid>
                    <Grid xs item sx={{ display: { xs: 'block', md: 'none' } }}>
                        <Box display='flex'/>
                    </Grid>
                    <Grid item xs textAlign='right'>
                        <Hidden mdDown>
                            <HeaderTabs/>
                        </Hidden>
                    </Grid>
                    <Grid item xs='auto' justifySelf='flex-end' alignContent='center'
                        sx={{ display: { xs: 'block', md: 'none' } }}
                    >
                        <SvgIcon color='blue' sx={{marginTop: '8px', marginRight: '26px'}} onClick={() => {setShowSandwhich(true)}}>
                            <BarIcon/>
                        </SvgIcon>
                    </Grid>
                    <HeaderSandwich onClose={() => setShowSandwhich(false)} open={showSandwhich}/>
                </Grid>
            </WallPaper>
        </Paper>
    )
}