import React, { useState } from 'react'
import { styled, Card, CardHeader, CardContent, Collapse, IconButton, SvgIcon } from '@mui/material'
import { UpArrow } from '../../icons/up-arrow'


const SectionContainer = styled(Card)(({theme}) => ({
    textAlign: 'left',
    borderRadius: '20px',
    boxShadow: '4px',
}))

const SectionHeader = styled(CardHeader)(({theme}) => ({
    color: 'black',
    background: `${theme.palette.secondary.main}`,
    border: `3px solid ${theme.palette.neutral.main}`,
    paddingLeft: '40px',
    paddingTop: '20px',
    paddingBottom: '30px',
    textAlign: 'left',
    '& .MuiCardHeader-title': {
        fontFamily: 'Montserrat',
        fontSize: '25px',
        lineHeight: '30px',
        fontWeight: 700
    },
    [theme.breakpoints.down('md')]: {
        '& .MuiCardHeader-title': {
            fontFamily: 'Montserrat',
            fontSize: '18px',
            lineHeight: '30px',
            fontWeight: 700
        },

    },
}))

const SectionBody = styled(CardContent)(({theme}) => ({
    background: `${theme.palette.neutral.main}`,
    paddingLeft: '40px',
    paddingTop: '32px',
    textAlign: 'left',
    fontFamily: 'Montserrat',
    fontSize: '18px',
    lineHeight: '22px',
    fontWeight: 500
}))

const ExpandMore = styled((props) => {
    const { expand, ...other } = props;
    return <IconButton {...other} />;
})(({ theme, expand }) => ({
    transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
        duration: theme.transitions.duration.shortest,
    }),
}))


export function FaqQuestionContainer(props) {
    const { title, children } = props

    const [expanded, setExpanded] = useState(false);

    const handleExpandClicked = () => {
        setExpanded(!expanded)
    }

    return (
        <SectionContainer>
            <SectionHeader title={title} 

                    onClick={handleExpandClicked}
            sx={{
                cursor: 'pointer',
                borderRadius: expanded ? '20px 20px 0 0': '20px',
            }} action={
                <ExpandMore
                    onClick={handleExpandClicked}
                    expand={expanded}
                >
                    <SvgIcon style={{transform: 'rotate(180deg)' }} viewBox='0 0 384.923 384.923'>
                        <UpArrow/>
                    </SvgIcon>
                </ExpandMore>
            }/>
            <Collapse in={expanded}>
                <SectionBody>
                    {children}
                </SectionBody>
            </Collapse>
        </SectionContainer>
    )
}