import React from 'react'

import { Grid, Typography, styled, useTheme, Box, Button, autocompleteClasses } from '@mui/material'

const ItemContainer = styled(`tr`)(({theme}) => ({
    margin: "auto",
    flexDirection:"row", 
    display:"flex",
    marginTop: "24px",
    alignItems: "center",
    maxWidth: "850px"
}))

const Image = styled(`img`)(({theme}) => ({
    // verticalAlign: 'middle',
    width: '80px',
    height: 'auto',
    display: 'block',
    marginRight: '50px',
}))

export function SourItem(props) {
    const theme = useTheme();
    const { img, content } = props
    return ( 
        <ItemContainer>
            <td> <Image src={img} /> </td>
            <td>    <Typography variant="body2" textAlign='left'>{content}</Typography></td>
        </ItemContainer>
    )
}