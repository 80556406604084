import React from 'react'
import { Grid, useTheme, Link } from '@mui/material'
import { FaqQuestionContainer } from './FaqQuestionContainer'
import { FaqHomeButton } from './FaqHomeButton'
import { FaqTitle } from '../../components/Faq/FaqTitle';

export function FaqLicense() {
    const theme = useTheme()
    return (
        <>
            <FaqTitle subTitle='Licensing &amp; Verification'></FaqTitle>
            <Grid container spacing={6} sx={{
                paddingLeft: '289px',
                paddingRight: '289px',
                paddingBottom: '90px',
                background: '#7285A5' , // no clue why i had to add this
                [theme.breakpoints.down('md')]: {
                    // No design here, im yolo-ing
                    paddingLeft: '35px',
                    paddingRight: '35px',
                }
            }}>
                <Grid item xs={12}>
                    <FaqQuestionContainer title='What is the teams stance on licensing/derivative work commercial right issues from "Cool Cats"?'>
                        <div>
                            There’s no denying the fact that we absolutely adore the Cool Cats project and are paying homage 
                            in a meme-able fashion for one of the most successful NFT projects to date with the Uncool Cats project. 
                            <br/><br/>
                            Co-existence with the cooler counterpart is our aim in this crazy NFT world we live in and we want to do 
                            our best to ensure we show respect to the original Cool Cats project. We consider our project transformative 
                            fair use and compliment the original project by producing tokens that are noticeably different and truly ‘uncool’ in a fun and laughable way.  
                        </div>
                        <br/>
                        <div>
                            We are not trying to be a run-of-the-mill derivative low effort project, 
                            unlike many copycats that have launched. Longevity and an involved community backed by bright founders, 
                            devs and illustrators that have a very exciting development roadmap for this project is what we strive for. 
                            <br/><br/>
                            If this topic is a fundamental issue or worry for some of you, we would recommend you do what works best for you and sit out on minting. 
                            We want to ensure a FUD-free environment that has dedicated collectors that make up a thriving community. 
                        </div>
                        <br/>
                        <div>
                            Additionally, we would recommend reading <Link href="https://nftevening.com/cool-cats-takes-a-clear-stance-on-license-and-derivative-works/">this article</Link> to ease any remaining concerns. 
                        </div>
                    </FaqQuestionContainer>
                </Grid>

                <Grid item xs={12}>
                    <FaqQuestionContainer title='What about OpenSea verification?'>
                        <div>
                            OpenSea has very strict rules regarding ‘derivative’ projects receiving a ‘blue checkmark’ for verification. 
                            The likelihood of this project being ‘verified’ on OpenSea after launch is a low probability, 
                            so if that is a make or break for you, we want to be upfront about that. 
                            <br/><br/>
                            The Uncool Cats team are big believers that a strong community and roadmap are key to success, 
                            not arduous requirements set by a company. The Uncool Cats team is extremely dedicated to producing a high quality project for years to come, 
                            but we wanted to be transparent about externalities that some may be unaware of prior to minting.
                        </div>
                    </FaqQuestionContainer>
                </Grid>
                <Grid item xs={12} textAlign='center' sx={{
                    marginTop: '60px'
                }}>
                    <FaqHomeButton/>
                </Grid>
            </Grid>
        </>
    )
}