import React from 'react';
import { Dialog, Button, useTheme, styled, Grid, Typography, Paper } from '@mui/material';
import { DiscordButton, OpenSeasButton, TwitterButton } from './SocialLinkButtons';

const DialogButton = styled(Button)(({theme}) => ({
    border: `5px solid ${theme.palette.neutral.main}`,
    [theme.breakpoints.down('lg')]: {
        fontSize: '30px',
        lineHeight: '30px',
    }
}))

const DialogTitle = styled(Typography)(({theme}) => ({
    paddingTop: '40px',
    [theme.breakpoints.down('lg')]: {
        fontSize: '42px',
        lineHeight: '42px'
    }
}))

const StyledLink = styled(`a`)(({theme}) => ({
    fontFamily: 'var(--font-family-proxima_soft-extrabold)',
    fontSize: '22px',
    lineHeight: '22px',
    fontWeight: 800,
    color: theme.palette.neutral.main,
    textDecoration: 'none',
    '&:visited': {
        color: ''
    }
}))

export function HeaderSandwich(props) {

    const { onClose, open } = props;

    const handleClose = () => {
        onClose();
    }

    const theme = useTheme();

    const socialStyling = {
        border: `5px solid ${theme.palette.neutral.main}`,
        marginLeft: '4px',
        marginRight: '4px',
        marginBottom: '32px',
    }

    // Add a way to close this shit, 
    // Add vertical tabs and fix the styling using vertical styling shit
    return (
        <Dialog onClose={handleClose} open={open}>
            <Grid container spacing={2} textAlign='center'>
                <Grid item xs={12}>
                    <Paper sx={{
                        height: '90px',
                        // To make this an image, replace background with backgroundImage and an image
                        background: `${theme.palette.neutral.main}`,
                    }}>
                        <DialogTitle variant='h2'>uNcool Cats</DialogTitle>
                    </Paper>                    
                </Grid>
                <Grid item xs={12}>
                    <StyledLink href='/mint'>
                        MINT
                    </StyledLink>
                </Grid>
                <Grid item xs={12}>
                    <StyledLink href='/'>
                        HOME
                    </StyledLink>
                </Grid>
                <Grid item xs={12}>
                    <StyledLink href='/faq'>
                        FAQ
                    </StyledLink>
                </Grid>
                <Grid item xs={12}>
                    <StyledLink href='/wallet'>
                        WALLET
                    </StyledLink>
                </Grid>
                <Grid item xs={12}>
                    <StyledLink href='/sour'>
                        SOUR
                    </StyledLink>
                </Grid>
                

                {/* TODO: include when contract is live */ }
                {/* <Grid item xs={12}>
                    <StyledLink href='/contract'>
                        CONTRACT
                    </StyledLink>
                </Grid> */}
                <Grid item xs={12}>
                    <DiscordButton sx={socialStyling}/>
                    <OpenSeasButton sx={socialStyling}/>
                    <TwitterButton sx={socialStyling}/>
                </Grid>
            </Grid>
        </Dialog>
    )
}