import React, { useState } from 'react'
import { Grid, Typography, useTheme, Box, Card, CardActions, Collapse } from '@mui/material'
import { DarkSection } from '../lib/DarkSection'
import UpperImage from '../../../static/img/upper-wave1.png';
import LowerImage from '../../../static/img/header-background.png';
import { TvImage } from '../TvImage'

export function Introduction() {
    const theme = useTheme();

    const [expanded, setExpanded] = useState(false)

    return (
        <DarkSection
            upperImage={UpperImage}
            upperHeight='191px'
            lowerImage={LowerImage}
            lowerHeight='320px'
        >
            <Grid container sx={{
                paddingLeft: '144px',
                [theme.breakpoints.down('lg')]: {
                    paddingLeft: '32px',
                    paddingRight: '32px',
                    justifyContent: 'center',
                }
            }}>
                <Grid item sm={12} md={6}>
                    <Grid item xs={12}>
                        <Typography variant="h1">MEET THE PATHETIC FELINES</Typography>
                    </Grid>
                    <Grid item xs={12} marginTop='24px'>
                        <Typography variant="h2">
                            While these Uncool Cats won’t be invited to parties, they can definitely do your homework!
                        </Typography>
                    </Grid>
                    <Grid item xs={12}
                        sx={{
                            display: { xs: 'block', md: 'none' }
                        }}>
                            <TvImage content='img/rain-gif.gif' frame='/img/tv-frame.png'/>
                    </Grid>
                    <Grid item xs={12} marginTop='40px'>
                        <Typography component={'div'} variant="body1">
                            <div>
                                We live in an unjust world where not all cats are created equally. 
                                We can't all be cool, right? Don't let it rain on your parade!
                            </div>
                            <br/>
                            <div>
                                Uncool Cats is a collection of 6969 cats living on the Ethereum blockchain as ERC-721 non-fungible tokens (NFTs).
                                These cats have been through the wringer - stuffed in lockers, do the cool cats' homework, 
                                lack a skincare routine and otherwise are down on their luck! Don't pet these cats, they haven't showered in weeks!
                            </div>
                        </Typography>
                        <Card sx={{
                            background: 'transparent',
                            boxShadow: 0,
                        }}>
                            <CardActions sx={{padding: 0}}>
                                <Typography variant='h2' onClick={() => setExpanded(true)} sx={{
                                    opacity: `${!expanded ? 1: 0}`,
                                    padding: 0,
                                    fontSize: '20px',
                                    lineHeight: '20px',
                                    paddingTop: '15px',
                                    cursor: 'pointer',
                                    borderBottom: `1px solid ${theme.palette.secondary.main}`
                                }}>
                                    [READ MORE]
                                </Typography>
                            </CardActions>
                            <Collapse in={expanded}>
                                <Typography component={'div'} variant="body1">
                                    An ecosystem of Uncool Cats by the community, for the community, with incredible art design and $SOUR yielding
                                </Typography>
                                <Typography variant='h2' onClick={() => setExpanded(false)} sx={{
                                    fontSize: '20px',
                                    lineHeight: '20px',
                                    paddingTop: '25px',
                                    borderBottom: `1px solid ${theme.palette.secondary.main}`,
                                    cursor: 'pointer',
                                    width: 'fit-content'
                                }}>
                                    [HIDE]
                                </Typography>
                            </Collapse>
                        </Card>
                        <Box height="60px"/>
                    </Grid>
                </Grid>
                <Grid item xs={6} paddingLeft='16px'
                    sx={{
                        display: { xs: 'none', md: 'block' }
                    }}
                >
                    <TvImage content='img/rain-gif.gif' frame='/img/tv-frame.png'/>
                </Grid>
            </Grid>
        </DarkSection>
    )
}