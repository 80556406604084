import React from 'react'
import { Paper, styled } from '@mui/material'

const StyledPaper = styled(Paper)(({theme}) => ({
    backgroundRepeat: 'round',
    backgroundColor: 'transparent',
    position:'relative',
    minHeight: '320px',
    maxWidth: '2100px',
    boxShadow: 0,
    zIndex: 2,
    [theme.breakpoints.down('md')]: {
        backgroundRepeat: 'no-repeat',
    }
}))

export function WallPaper(props) {
    const { minHeight, image, children, sx } = props

    return (
        <StyledPaper elevation={0} sx={{
            minHeight: minHeight,
            backgroundImage: `url(${image})`,
            ...sx
        }}>
            {children}
        </StyledPaper>
    )
}