import React from 'react'
import { styled, Grid } from '@mui/material'

const DataRow = styled(Grid)(({theme}) => ({
    fontFamily: 'Montserrat',
    fontSize: '35px',
    fontWeight: '700',
    lineHeight: '42px',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    [theme.breakpoints.down('lg')]: {
        fontSize: '25px',
        lineHeight: '30px',
    },
    "&::after": {
        [theme.breakpoints.up('md')]: {
            content: '" ...................................................................................................................................."'
        }
    }
}))

const Data = styled(Grid)(({theme}) => ({
    fontFamily: 'Montserrat',
    fontSize: '35px',
    fontWeight: '400',
    lineHeight: '42px',
    color: 'white',
    [theme.breakpoints.down('lg')]: {
        fontSize: '25px',
        lineHeight: '30px',
    },
}))

export function MintDataList(props) {
    
    const { label, data } = props

    return (
        <Grid container sx={{
            marginTop: '12px',
            textAlign: 'left',
        }}>
            <DataRow item xs={12} md>
                {label}
            </DataRow>
            <Data item xs={12} md='auto'>
                {data}
            </Data>
        </Grid>
    )
}